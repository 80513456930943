import React, { Component } from 'react';
import { Row, Col, Switch, message, Table, Spin } from 'antd';
import PlotDomain from './plot';
import { useParams } from 'react-router-dom';
import { _interpro } from './table_config';
import asyncFetch from '../../utils/asyncFetch';

const WithDomain = (props) => {
    let params = useParams();
    return <Domain {...props} params={params} />
}

class Domain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            record: [],
            checked: true,
            loading: true
        }
    }

    componentDidMount() {
        const { gene } = this.state;
        asyncFetch('GET', '/basic_table/interpro_data', {SYMBOL: gene, current_page: 1, page_size: 10000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    onChange = (checked) => {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                checked,
                loading: false
            });
        }, 1000);
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Switch checkedChildren="Table" unCheckedChildren="Picture" defaultChecked onChange={this.onChange} style={{marginBottom: '20px'}} />
                        {
                            this.state.checked ? (
                                <Table
                                    scroll={{x: 1200}}
                                    columns={_interpro}
                                    dataSource={this.state.record}
                                />
                            ) : (
                                <PlotDomain record={this.state.record} />
                            )
                        }
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default WithDomain