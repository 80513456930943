import React, { Component } from 'react';
import { Row, Col, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import asyncFetch from '../../utils/asyncFetch';

import ThridText from '../../components/ThridText'

const WithBasicInfo = (props) => {
    let params = useParams();
    return <BasicInfo {...props} params={params} />
}

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: [],
            gene: this.props.params.gene,
            loading: true
        }
    }

    componentDidMount() {
        asyncFetch('GET', '/basic_table/gene_data', {SYMBOL: this.state.gene, current_page: 1, page_size: 1},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    render() {
        const { record, loading } = this.state
        let obj = record[0] || {}
        return (
            <Spin spinning={loading}>
                <Row>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            Gene Name:
                        </span>
                        <i>
                            <ThridText contact={obj['SYMBOL'] || "NA"} />
                        </i>
                    </Col>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            Entrez gene:
                        </span>
                        <ThridText contact={obj['Entrez_id'] || "NA"} color="#02709b" background="#f6f6f6" link={true} url="https://www.ncbi.nlm.nih.gov/gene/?term=" />
                    </Col>
                    <Col span={24}>
                        <span className='variant-thrid-title'>
                            Previous HGNC symbols:
                        </span>
                        <ThridText contact={obj['Previous_HGNC_symbols'] || "NA"} />
                    </Col>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            Ensembl:
                        </span>
                        <ThridText contact={obj['Ensembl_id'] || "NA"} color="#02709b" background="#f6f6f6" link={true} url="http://ensembl.org/Homo_sapiens/Gene/Summary?g=" />
                    </Col>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            HGNC:
                        </span>
                        <ThridText contact={obj['HGNC_id'] || "NA"} color="#02709b" background="#f6f6f6" link={true} url="https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/HGNC:" />
                    </Col>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            Uniprot:
                        </span>
                        <ThridText contact={obj['Uniprot_id'] || "NA"} color="#02709b" background="#f6f6f6" link={true} url="https://www.uniprot.org/uniprot/" />
                    </Col>
                    <Col span={12}>
                        <span className='variant-thrid-title'>
                            OMIM:
                        </span>
                        <ThridText contact={obj['Omim_id'] || "NA"} color="#02709b" background="#f6f6f6" link={true} url="https://omim.org/entry/" />
                    </Col>
                    <Col span={8}>
                        <span className='variant-thrid-title'>
                            Genomic coordinates (GRCh37):
                        </span>
                        <ThridText contact={obj['Chromosome_37'] + ":" + obj["Coordinates_37"] || "NA"} />
                    </Col>
                    <Col span={8}>
                        <span className='variant-thrid-title'>
                            Genomic coordinates (GRCh38):
                        </span>
                        <ThridText contact={obj['Chromosome_38'] + ":" + obj["Coordinates_38"] || "NA"} />
                    </Col>
                    <Col span={8}>
                        <span className='variant-thrid-title'>
                            Genomic coordinates (T2T-CHM13):
                        </span>
                        <ThridText contact={obj['Chromosome_CHM13'] + ":" + obj["Coordinates_CHM13"] || "NA"} />
                    </Col>
                    <Col span={24}>
                        <span className='variant-thrid-title'>
                            Summary(from Entrze gene):
                        </span>
                        <ThridText contact={obj['Summary'] || "NA"} />
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default WithBasicInfo