import React, { Component } from 'react';
import { Row, Col, Table, message, Switch, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { _go } from './table_config';
import asyncFetch from '../../utils/asyncFetch';

const WithFunction = (props) => {
    let params = useParams();
    return <Function {...props} params={params} />
};

const handleEvidence = (gene, record) => {
    let evidence = [], children = []
    record.forEach((val, idx) => {
        evidence.push(val.Evidence)
    })
    evidence = [...new Set(evidence)]
    evidence.forEach((val, idx) => {
        let tmp = handleName(val, record)
        children.push(tmp)
    })
    return [{
        name: gene,
        children: children
    }]
}

const handleName = (key, record) => {
    let item = [], sub = []
    record.forEach((v, i) => {
        if (key === v.Evidence) {
            item.push(v.Name.replace(/^(\s)+/, ''))
        }
    });
    const obj = item.reduce((obj, key) => {
        if (key in obj) {
            obj[key]++
        } else {
            obj[key] = 1
        }
        return obj
    }, {});
    for (const key in obj) {
        sub.push(
            {
                name: key,
                value: obj[key]
            }
        )
    }
    return {
        name: key,
        children: sub
    } 
}

class Function extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            record: [],
            data: [],
            checked: true,
            loading: true,
        }
    }

    componentDidMount() {
        const { gene } = this.state;
        asyncFetch('GET', '/basic_table/go_data', {SYMBOL: gene, current_page: 1, page_size: 10000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        data: handleEvidence(gene, result),
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    getOption() {
        return {
            visualMap: {
                type: 'continuous',
                min: 0,
                max: 10,
                inRange: {
                    color: ['#2F93C8', '#AEC48F', '#FFDB5C', '#F98862']
                }
            },
            series: {
                type: 'sunburst',
                data: this.state.data,
                radius: [0, '50%'],
                label: {
                    rotate: 'radial',
                    align: 'left'
                }
            }
        };
    }

    onChange = (checked) => {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                checked,
                loading: false
            });
        }, 1000);
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Switch checkedChildren="Table" unCheckedChildren="Picture" defaultChecked onChange={this.onChange} style={{marginBottom: '20px'}} />
                        {
                            this.state.checked ? (
                                <Table
                                    scroll={{x: 1200}}
                                    columns={_go}
                                    dataSource={this.state.record}
                                />
                            ) : (
                                <ReactEcharts option={this.getOption()} style={{height: '750px'}} />
                            )
                        }
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default WithFunction