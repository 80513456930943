import React, { Component } from 'react';
import { Row, Col, Form, Input, InputNumber, Radio, Checkbox, Button, Space, Table, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { _variant } from './table_config.js';
import asyncFetch from '../../utils/asyncFetch.js';

const WithVariant = (props) => {
    let params = useParams();
    return <Variant {...props} params={params} />
}

class Variant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            pageSize: 10,
            currentPage: 1,
            record: [],
            totalSize: 0,
            loading: true
        }
    }

    componentDidMount() {
        this.loading({});
    }

    formreset = React.createRef();

    loading = (values) => {
        const {gene, currentPage, pageSize} = this.state;
        let params = values;
        params['SYMBOL'] = gene;
        params['current_page'] = currentPage;
        params['page_size'] = pageSize;
        asyncFetch('GET', '/site', params,
            (res) => {
                if (res.code === 200) {
                    let result = []
                    res.data.forEach((val, idx) => {
                        val.key = idx;
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        totalSize: res.total,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    };

    onFinish = values => {
        let obj = {};
        for(var i in values) {
            if (typeof(values[i]) !== "undefined" && values[i]) {
                obj[i] = values[i]
            }
        };
        console.log(obj);
        this.loading(obj);
    };

    onReset = () => {
        this.formreset.current.resetFields();
    };

    pageChange = (page, size) => {
        this.setState({
            currentPage: page,
            pageSize: size
        })
        this.formreset.current.submit()
    };

    render() {
        const { pageSize, record, totalSize, loading } = this.state;
        return (
            <Row>
                <Col span={24}>
                    <Spin spinning={loading}>
                        <Form
                            name="variant-filter"
                            onFinish={this.onFinish}
                            colon={false}
                            className="variant-filter"
                            ref={this.formreset}
                            initialValues={{
                                site_version: 'GRCh38',
                            }}
                        >
                            <Row>
                                <Col span={24} className="box-border box-border-radius">
                                    <Form.Item
                                        label="Variant"
                                        labelCol={{span: 3}}
                                        wrapperCol={{span: 21}}
                                    >
                                        <Row gutter={16}>
                                            <Col
                                                xs={{ span: 24 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 4 }}
                                                lg={{ span: 4 }}
                                                xl={{ span: 4 }}
                                            >
                                                <Form.Item
                                                    name="site_version"
                                                >
                                                    <Radio.Group>
                                                        <Radio value="GRCh37">GRCh37</Radio>
                                                        <Radio value="GRCh38">GRCh38</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={{ span: 24 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 10 }}
                                                lg={{ span: 10 }}
                                                xl={{ span: 10 }}
                                            >
                                                <Form.Item
                                                    name="match_key"
                                                >
                                                    <Input placeholder="13-20189473-C-T, 13-20189473, rs72474224, c.109G>A, p.Val37Ile" />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={{ span: 24 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 3 }}
                                                lg={{ span: 3 }}
                                                xl={{ span: 3 }}
                                            >
                                                <Form.Item
                                                    name="range"
                                                >
                                                    <Space style={{ width: '100%' }}>
                                                        +/-
                                                        <InputNumber min={1} style={{ width: '100%' }} placeholder='100'/>
                                                    </Space>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={{ span: 24 }}
                                                sm={{ span: 24 }}
                                                md={{ span: 24 }}
                                                lg={{ span: 7 }}
                                                xl={{ span: 7 }}
                                                style={{height: '36px', lineHeight: '36px'}}
                                            >
                                                1Kb upstream and downstream of a variant site
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                            className="box-border"
                                        >
                                            <Form.Item
                                                label="CDGC"
                                                name="cdgc"
                                                labelCol={{span: 6}}
                                                wrapperCol={{span: 18}}
                                            >
                                                <Checkbox.Group>
                                                    <Checkbox value="P">P</Checkbox>
                                                    <Checkbox value="LP">LP</Checkbox>
                                                    <Checkbox value="V">V</Checkbox>
                                                    <Checkbox value="LB">LB</Checkbox>
                                                    <Checkbox value="B">B</Checkbox>
                                                    <Checkbox value="NA">NA</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col 
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                            className="box-border"
                                        >
                                            <Form.Item
                                                label="ClinVar"
                                                name="clinvar"
                                                labelCol={{span: 6}}
                                                wrapperCol={{span: 18}}
                                            >
                                                <Checkbox.Group>
                                                    <Checkbox value="P">P</Checkbox>
                                                    <Checkbox value="LP">LP</Checkbox>
                                                    <Checkbox value="V">V</Checkbox>
                                                    <Checkbox value="LB">LB</Checkbox>
                                                    <Checkbox value="B">B</Checkbox>
                                                    <Checkbox value="NA">NA</Checkbox>
                                                    <Checkbox value="other">Other</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col 
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                            className="box-border"
                                        >
                                            <Form.Item
                                                label="DVD"
                                                name="dvd"
                                                labelCol={{span: 6}}
                                                wrapperCol={{span: 18}}
                                            >
                                                <Checkbox.Group>
                                                    <Checkbox value="P">P</Checkbox>
                                                    <Checkbox value="LP">LP</Checkbox>
                                                    <Checkbox value="V">V</Checkbox>
                                                    <Checkbox value="LB">LB</Checkbox>
                                                    <Checkbox value="B">B</Checkbox>
                                                    <Checkbox value="NA">NA</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                            className="box-border"
                                        >
                                            <Form.Item
                                                label="HGMD"
                                                name="hgmd"
                                                labelCol={{span: 6}}
                                                wrapperCol={{span: 18}}
                                            >
                                                <Checkbox.Group>
                                                    <Checkbox value="P">P</Checkbox>
                                                    <Checkbox value="LP">LP</Checkbox>
                                                    <Checkbox value="NA">NA</Checkbox>
                                                    <Checkbox value="other">Other</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}  className="box-border">
                                    <Form.Item
                                        label="gnomAD Max MAF"
                                        labelCol={{span: 3}}
                                        wrapperCol={{span: 21}}
                                    >
                                        <Form.Item
                                            name="range_min"
                                            style={{ display: 'inline-block', width: 'calc(100px - 12px)' }}
                                        >
                                            <InputNumber min={0} max={1} step={0.001} />
                                        </Form.Item>
                                        <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}>
                                            -
                                        </span>
                                        <Form.Item
                                            name="range_max"
                                            style={{ display: 'inline-block', width: 'calc(110px - 12px)' }}
                                        >
                                            <InputNumber min={0} max={1} step={0.001} />
                                        </Form.Item>
                                        <Form.Item
                                            name="novel"
                                            style={{ display: 'inline-block', width: '200px' }}
                                        >
                                            <Checkbox.Group>
                                                <Checkbox value="Novel">No record for gnomAD</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                                <Col span={24}  className="box-border">
                                    <Form.Item
                                        label="Consequence"
                                        name="consequence"
                                        labelCol={{span: 3}}
                                        wrapperCol={{span: 21}}
                                    >
                                        <Checkbox.Group>
                                            <Checkbox value="Loss of function">Loss of function</Checkbox>
                                            <Checkbox value="Missense and in-frame indels">Missense and in-frame indels</Checkbox>
                                            <Checkbox value="Synonymous and splice region">Synonymous and splice region</Checkbox>
                                            <Checkbox value="Other">Other</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Space style={{margin: '10px 0'}}>
                                    <Button className='reset-btn' onClick={this.onReset}>
                                        Reset
                                    </Button>
                                    <Button className='query-btn' htmlType="submit">
                                        Query
                                    </Button>
                                    Total: 
                                    {totalSize}
                                </Space>
                            </Form.Item>
                        </Form>
                        <Table
                            columns={_variant}
                            dataSource={record}
                            scroll={{ x: 1200 }}
                            pagination={{
                                size: "small",
                                pageSizeOptions: [5, 10, 20, 50, 100, totalSize],
                                defaultPageSize: pageSize,
                                total: totalSize,
                                onChange: this.pageChange,
                            }}
                        />
                    </Spin>
                </Col>
            </Row>
        )
    }
}

export default WithVariant