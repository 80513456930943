const dataZh = [
    {name: "重庆市", value: 1331},
    {name: "北京市", value: 1109},
    {name: "乌鲁木齐市", value: 1056},
    {name: "南宁市", value: 899},
    {name: "济南市", value: 864},
    {name: "兰州市", value: 765},
    {name: "石家庄市", value: 556},
    {name: "济宁市", value: 478},
    {name: "临沂市", value: 444},
    {name: "郑州市", value: 406},
    {name: "菏泽市", value: 387},
    {name: "长沙市", value: 315},
    {name: "银川市", value: 312},
    {name: "南昌市", value: 307},
    {name: "潍坊市", value: 300},
    {name: "徐州市", value: 295},
    {name: "西安市", value: 280},
    {name: "贵阳市", value: 276},
    {name: "昭通市", value: 269},
    {name: "上海市", value: 257},
    {name: "杭州市", value: 241},
    {name: "西宁市", value: 233},
    {name: "南充市", value: 231},
    {name: "哈尔滨市", value: 228},
    {name: "宿迁市", value: 223},
    {name: "成都市", value: 221},
    {name: "合肥市", value: 214},
    {name: "天津市", value: 209},
    {name: "黔东南苗族侗族自治州", value: 189},
    {name: "长春市", value: 186},
    {name: "武汉市", value: 175},
    {name: "南京市", value: 173},
    {name: "宜宾市", value: 172},
    {name: "玉林市", value: 169},
    {name: "邢台市", value: 147},
    {name: "邯郸市", value: 146},
    {name: "赣州市", value: 145},
    {name: "临夏回族自治州", value: 143},
    {name: "海口市", value: 142},
    {name: "钦州市", value: 141},
    {name: "乐山市", value: 140},
    {name: "宜昌市", value: 138},
    {name: "太原市", value: 138},
    {name: "沈阳市", value: 138},
    {name: "枣庄市", value: 138},
    {name: "梧州市", value: 137},
    {name: "呼和浩特市", value: 128},
    {name: "龙岩市", value: 127},
    {name: "绵阳市", value: 123},
    {name: "通辽市", value: 121},
    {name: "巴中市", value: 120},
    {name: "阜阳市", value: 118},
    {name: "聊城市", value: 117},
    {name: "福州市", value: 112},
    {name: "德阳市", value: 111},
    {name: "保山市", value: 110},
    {name: "德州市", value: 110},
    {name: "达州市", value: 105},
    {name: "广州市", value: 104},
    {name: "保定市", value: 99},
    {name: "宿州市", value: 97},
    {name: "贵港市", value: 96},
    {name: "南阳市", value: 96},
    {name: "泰安市", value: 95},
    {name: "连云港市", value: 94},
    {name: "遵义市", value: 92},
    {name: "昆明市", value: 89},
    {name: "荆门市", value: 73},
    {name: "四平市", value: 72},
    {name: "淄博市", value: 69},
    {name: "日喀则市", value: 69},
    {name: "淮北市", value: 68},
    {name: "黄石市", value: 68},
    {name: "商丘市", value: 68},
    {name: "荆州市", value: 67},
    {name: "拉萨市", value: 66},
    {name: "广安市", value: 66},
    {name: "吕梁市", value: 64},
    {name: "日照市", value: 63},
    {name: "贺州市", value: 62},
    {name: "自贡市", value: 59},
    {name: "桂林市", value: 59},
    {name: "绥化市", value: 56},
    {name: "来宾市", value: 56},
    {name: "周口市", value: 55},
    {name: "东营市", value: 55},
    {name: "开封市", value: 54},
    {name: "宜春市", value: 54},
    {name: "内江市", value: 49},
    {name: "喀什地区", value: 48},
    {name: "邵阳市", value: 45},
    {name: "遂宁市", value: 45},
    {name: "山南市", value: 45},
    {name: "岳阳市", value: 44},
    {name: "晋中市", value: 44},
    {name: "鄂尔多斯市", value: 44},
    {name: "包头市", value: 43},
    {name: "辽源市", value: 43},
    {name: "齐齐哈尔市", value: 43},
    {name: "乌兰察布市", value: 42},
    {name: "广元市", value: 42},
    {name: "资阳市", value: 40},
    {name: "衡阳市", value: 38},
    {name: "曲靖市", value: 38},
    {name: "衡水市", value: 37},
    {name: "信阳市", value: 37},
    {name: "襄阳市", value: 36},
    {name: "佛山市", value: 36},
    {name: "百色市", value: 36},
    {name: "黄冈市", value: 36},
    {name: "雅安市", value: 36},
    {name: "沧州市", value: 35},
    {name: "毕节市", value: 35},
    {name: "安阳市", value: 33},
    {name: "上饶市", value: 33},
    {name: "铜仁市", value: 33},
    {name: "抚州市", value: 32},
    {name: "湘潭市", value: 32},
    {name: "吉安市", value: 32},
    {name: "宁波市", value: 32},
    {name: "忻州市", value: 32},
    {name: "北海市", value: 32},
    {name: "驻马店市", value: 30},
    {name: "肇庆市", value: 30},
    {name: "许昌市", value: 28},
    {name: "楚雄彝族自治州", value: 27},
    {name: "常德市", value: 27},
    {name: "运城市", value: 27},
    {name: "揭阳市", value: 27},
    {name: "那曲市", value: 26},
    {name: "大同市", value: 25},
    {name: "长治市", value: 25},
    {name: "崇左市", value: 25},
    {name: "娄底市", value: 25},
    {name: "郴州市", value: 24},
    {name: "九江市", value: 24},
    {name: "昌都市", value: 23},
    {name: "台州市", value: 23},
    {name: "洛阳市", value: 23},
    {name: "滨州市", value: 23},
    {name: "临汾市", value: 23},
    {name: "柳州市", value: 23},
    {name: "河池市", value: 23},
    {name: "承德市", value: 22},
    {name: "晋城市", value: 22},
    {name: "金华市", value: 22},
    {name: "烟台市", value: 22},
    {name: "凉山彝族自治州", value: 22},
    {name: "儋州市", value: 22},
    {name: "汕尾市", value: 22},
    {name: "宁德市", value: 22},
    {name: "株洲市", value: 22},
    {name: "唐山市", value: 21},
    {name: "葫芦岛市", value: 21},
    {name: "怀化市", value: 21},
    {name: "汕头市", value: 21},
    {name: "永州市", value: 21},
    {name: "茂名市", value: 21},
    {name: "朝阳市", value: 20},
    {name: "衢州市", value: 20},
    {name: "红河哈尼族彝族自治州", value: 20},
    {name: "温州市", value: 20},
    {name: "鞍山市", value: 20},
    {name: "赤峰市", value: 20},
    {name: "新乡市", value: 19},
    {name: "黔西南布依族苗族自治州", value: 19},
    {name: "恩施土家族苗族自治州", value: 19},
    {name: "江门市", value: 19},
    {name: "青岛市", value: 19},
    {name: "锦州市", value: 19},
    {name: "澄迈县", value: 19},
    {name: "泸州市", value: 19},
    {name: "河源市", value: 18},
    {name: "黔南布依族苗族自治州", value: 18},
    {name: "平顶山市", value: 18},
    {name: "临高县", value: 17},
    {name: "益阳市", value: 17},
    {name: "濮阳市", value: 17},
    {name: "营口市", value: 16},
    {name: "萍乡市", value: 16},
    {name: "朔州市", value: 16},
    {name: "深圳市", value: 16},
    {name: "铁岭市", value: 15},
    {name: "林芝市", value: 15},
    {name: "廊坊市", value: 15},
    {name: "云浮市", value: 14},
    {name: "东方市", value: 14},
    {name: "大连市", value: 14},
    {name: "张家口市", value: 14},
    {name: "普洱市", value: 14},
    {name: "文山壮族苗族自治州", value: 14},
    {name: "眉山市", value: 13},
    {name: "鹰潭市", value: 13},
    {name: "焦作市", value: 13},
    {name: "孝感市", value: 13},
    {name: "万宁市", value: 12},
    {name: "榆林市", value: 12},
    {name: "梅州市", value: 12},
    {name: "湛江市", value: 11},
    {name: "鄂州市", value: 11},
    {name: "清远市", value: 11},
    {name: "三门峡市", value: 11},
    {name: "巴彦淖尔市", value: 11},
    {name: "呼伦贝尔市", value: 11},
    {name: "六盘水市", value: 10},
    {name: "德宏傣族景颇族自治州", value: 10},
    {name: "牡丹江市", value: 10},
    {name: "防城港市", value: 10},
    {name: "张掖市", value: 10},
    {name: "漯河市", value: 10},
    {name: "丹东市", value: 10},
    {name: "惠州市", value: 10},
    {name: "韶关市", value: 10},
    {name: "丽水市", value: 10},
    {name: "白城市", value: 9},
    {name: "吉林市", value: 9},
    {name: "辽阳市", value: 9},
    {name: "抚顺市", value: 9},
    {name: "通化市", value: 9},
    {name: "屯昌县", value: 9},
    {name: "琼海市", value: 9},
    {name: "陵水黎族自治县", value: 9},
    {name: "大庆市", value: 9},
    {name: "本溪市", value: 9},
    {name: "东莞市", value: 9},
    {name: "鸡西市", value: 9},
    {name: "绍兴市", value: 8},
    {name: "随州市", value: 8},
    {name: "安庆市", value: 8},
    {name: "湖州市", value: 8},
    {name: "莆田市", value: 8},
    {name: "渭南市", value: 8},
    {name: "潮州市", value: 8},
    {name: "阿坝藏族羌族自治州", value: 8},
    {name: "阜新市", value: 7},
    {name: "咸宁市", value: 7},
    {name: "阿里地区", value: 7},
    {name: "丽江市", value: 7},
    {name: "安顺市", value: 7},
    {name: "盘锦市", value: 7},
    {name: "阳泉市", value: 7},
    {name: "南通市", value: 7},
    {name: "潜江市", value: 6},
    {name: "新余市", value: 6},
    {name: "亳州市", value: 5},
    {name: "扬州市", value: 5},
    {name: "南平市", value: 5},
    {name: "佳木斯市", value: 5},
    {name: "泉州市", value: 5},
    {name: "中山市", value: 5},
    {name: "天门市", value: 5},
    {name: "临沧市", value: 4},
    {name: "七台河市", value: 4},
    {name: "湘西土家族苗族自治州", value: 4},
    {name: "兴安盟", value: 4},
    {name: "嘉兴市", value: 4},
    {name: "阳江市", value: 4},
    {name: "大兴安岭地区", value: 4},
    {name: "定安县", value: 4},
    {name: "三明市", value: 4},
    {name: "双鸭山市", value: 4},
    {name: "乐东黎族自治县", value: 4},
    {name: "伊春市", value: 4},
    {name: "秦皇岛市", value: 4},
    {name: "白山市", value: 3},
    {name: "珠海市", value: 3},
    {name: "鹤壁市", value: 3},
    {name: "怒江傈僳族自治州", value: 3},
    {name: "西双版纳傣族自治州", value: 3},
    {name: "三亚市", value: 3},
    {name: "庆阳市", value: 3},
    {name: "舟山市", value: 3},
    {name: "甘孜藏族自治州", value: 3},
    {name: "攀枝花市", value: 3},
    {name: "无锡市", value: 3},
    {name: "锡林郭勒盟", value: 3},
    {name: "宝鸡市", value: 3},
    {name: "盐城市", value: 3},
    {name: "延边朝鲜族自治州", value: 3},
    {name: "昌江黎族自治县", value: 3},
    {name: "景德镇市", value: 3},
    {name: "文昌市", value: 3},
    {name: "威海市", value: 2},
    {name: "神农架林区", value: 2},
    {name: "蚌埠市", value: 2},
    {name: "泰州市", value: 2},
    {name: "大理白族自治州", value: 2},
    {name: "淮安市", value: 2},
    {name: "仙桃市", value: 2},
    {name: "延安市", value: 2},
    {name: "陇南市", value: 2},
    {name: "海西蒙古族藏族自治州", value: 2},
    {name: "苏州市", value: 2},
    {name: "鹤岗市", value: 2},
    {name: "定西市", value: 2},
    {name: "松原市", value: 2},
    {name: "镇江市", value: 2},
    {name: "常州市", value: 2},
    {name: "乌海市", value: 2},
    {name: "武威市", value: 1},
    {name: "汉中市", value: 1},
    {name: "香港", value: 1},
    {name: "克孜勒苏柯尔克孜自治州", value: 1},
    {name: "漳州市", value: 1},
    {name: "五指山市", value: 1},
    {name: "芜湖市", value: 1},
    {name: "石嘴山市", value: 1},
    {name: "滁州市", value: 1},
    {name: "六安市", value: 1},
    {name: "酒泉市", value: 1},
    {name: "固原市", value: 1},
    {name: "铜陵市", value: 1},
    {name: "黑河市", value: 1},
    {name: "吴忠市", value: 1},
    {name: "平凉市", value: 1},
    {name: "阿拉善盟", value: 1},
    {name: "淮南市", value: 1},
    {name: "张家界市", value: 1},
    {name: "白沙黎族自治县", value: 1},
    {name: "石河子市", value: 1}
];

const dataEn = [
    {name: "Chongqing", value: 1331},
    {name: "Beijing", value: 1109},
    {name: "Urumqi", value: 1056},
    {name: "Nanning", value: 899},
    {name: "Jinan", value: 864},
    {name: "Lanzhou", value: 765},
    {name: "Shijiazhuang", value: 556},
    {name: "Jining", value: 478},
    {name: "Linyi", value: 444},
    {name: "Zhengzhou", value: 406},
    {name: "Heze", value: 387},
    {name: "Changsha", value: 315},
    {name: "Yinchuan", value: 312},
    {name: "Nanchang", value: 307},
    {name: "Weifang", value: 300},
    {name: "Xuzhou", value: 295},
    {name: "Xi'an", value: 280},
    {name: "Guiyang", value: 276},
    {name: "Zhaotong", value: 269},
    {name: "Shanghai", value: 257},
    {name: "Hangzhou", value: 241},
    {name: "Xining", value: 233},
    {name: "Nanchong", value: 231},
    {name: "Harbin", value: 228},
    {name: "Suqian", value: 223},
    {name: "Chengdu", value: 221},
    {name: "Hefei", value: 214},
    {name: "Tianjin", value: 209},
    {name: "Qiandongnan", value: 189},
    {name: "Changchun", value: 186},
    {name: "Wuhan", value: 175},
    {name: "Nanjing", value: 173},
    {name: "Yibin", value: 172},
    {name: "Yu'lin", value: 169},
    {name: "Xingtai", value: 147},
    {name: "Handan", value: 146},
    {name: "Ganzhou", value: 145},
    {name: "Linxia", value: 143},
    {name: "Haikou", value: 142},
    {name: "Qinzhou", value: 141},
    {name: "Leshan", value: 140},
    {name: "Yichang", value: 138},
    {name: "Taiyuan", value: 138},
    {name: "Shenyang", value: 138},
    {name: "Zaozhuang", value: 138},
    {name: "Wuzhou", value: 137},
    {name: "Hohhot", value: 128},
    {name: "Longyan", value: 127},
    {name: "Mianyang", value: 123},
    {name: "Tongliao", value: 121},
    {name: "Bazhong", value: 120},
    {name: "Fuyang", value: 118},
    {name: "Liaocheng", value: 117},
    {name: "Fu'zhou", value: 112},
    {name: "Deyang", value: 111},
    {name: "Baoshan", value: 110},
    {name: "Dezhou", value: 110},
    {name: "Dazhou", value: 105},
    {name: "Guangzhou", value: 104},
    {name: "Baoding", value: 99},
    {name: "Su'zhou", value: 97},
    {name: "Guigang", value: 96},
    {name: "Nanyang", value: 96},
    {name: "Tai'an", value: 95},
    {name: "Lianyungang", value: 94},
    {name: "Zunyi", value: 92},
    {name: "Kunming", value: 89},
    {name: "Jingmen", value: 73},
    {name: "Siping", value: 72},
    {name: "Zibo", value: 69},
    {name: "Shigatse", value: 69},
    {name: "Huaibei", value: 68},
    {name: "Huangshi", value: 68},
    {name: "Shangqiu", value: 68},
    {name: "Jingzhou", value: 67},
    {name: "Lhasa", value: 66},
    {name: "Guang'an", value: 66},
    {name: "Lvliang", value: 64},
    {name: "Rizhao", value: 63},
    {name: "Hezhou", value: 62},
    {name: "Zigong", value: 59},
    {name: "Guilin", value: 59},
    {name: "Suihua", value: 56},
    {name: "Laibin", value: 56},
    {name: "Zhoukou", value: 55},
    {name: "Dongying", value: 55},
    {name: "Kaifeng", value: 54},
    {name: "Yichun", value: 54},
    {name: "Neijiang", value: 49},
    {name: "Kashgar", value: 48},
    {name: "Shaoyang", value: 45},
    {name: "Suining", value: 45},
    {name: "Shannan", value: 45},
    {name: "Yueyang", value: 44},
    {name: "Jinzhong", value: 44},
    {name: "Ordos", value: 44},
    {name: "Baotou", value: 43},
    {name: "Liaoyuan", value: 43},
    {name: "Qiqihar", value: 43},
    {name: "Ulanqab", value: 42},
    {name: "Guangyuan", value: 42},
    {name: "Ziyang", value: 40},
    {name: "Hengyang", value: 38},
    {name: "Qujing", value: 38},
    {name: "Hengshui", value: 37},
    {name: "Xinyang", value: 37},
    {name: "Xiangyang", value: 36},
    {name: "Foshan", value: 36},
    {name: "Baise", value: 36},
    {name: "Huanggang", value: 36},
    {name: "Ya'an", value: 36},
    {name: "Cangzhou", value: 35},
    {name: "Bijie", value: 35},
    {name: "Anyang", value: 33},
    {name: "Shangrao", value: 33},
    {name: "Tongren", value: 33},
    {name: "Fuzhou", value: 32},
    {name: "Xiangtan", value: 32},
    {name: "Ji'an", value: 32},
    {name: "Ningbo", value: 32},
    {name: "Xinzhou", value: 32},
    {name: "Beihai", value: 32},
    {name: "Zhumadian", value: 30},
    {name: "Zhaoqing", value: 30},
    {name: "Xuchang", value: 28},
    {name: "Chuxiong", value: 27},
    {name: "Changde", value: 27},
    {name: "Yuncheng", value: 27},
    {name: "Jieyang", value: 27},
    {name: "Nagqu", value: 26},
    {name: "Datong", value: 25},
    {name: "Changzhi", value: 25},
    {name: "Chongzuo", value: 25},
    {name: "Loudi", value: 25},
    {name: "Chenzhou", value: 24},
    {name: "Jiujiang", value: 24},
    {name: "Changdu", value: 23},
    {name: "Taizhou", value: 23},
    {name: "Luoyang", value: 23},
    {name: "Binzhou", value: 23},
    {name: "Lingao", value: 23},
    {name: "Liuzhou", value: 23},
    {name: "Hechi", value: 23},
    {name: "Chengde", value: 22},
    {name: "Jincheng", value: 22},
    {name: "Jinhua", value: 22},
    {name: "Yantai", value: 22},
    {name: "Liangshan", value: 22},
    {name: "Danzhou", value: 22},
    {name: "Shanwei", value: 22},
    {name: "Ningde", value: 22},
    {name: "Zhuzhou", value: 22},
    {name: "Tangshan", value: 21},
    {name: "Huludao", value: 21},
    {name: "Huaihua", value: 21},
    {name: "Shantou", value: 21},
    {name: "Yongzhou", value: 21},
    {name: "Maoming", value: 21},
    {name: "Chaoyang", value: 20},
    {name: "Quzhou", value: 20},
    {name: "Honghe", value: 20},
    {name: "Wenzhou", value: 20},
    {name: "Anshan", value: 20},
    {name: "Chifeng", value: 20},
    {name: "Xinxiang", value: 19},
    {name: "Qianxinan", value: 19},
    {name: "Enshi", value: 19},
    {name: "Jiangmen", value: 19},
    {name: "Qingdao", value: 19},
    {name: "Jinzhou", value: 19},
    {name: "Chengmai", value: 19},
    {name: "Luzhou", value: 19},
    {name: "Heyuan", value: 18},
    {name: "Qiannan", value: 18},
    {name: "Pingdingshan", value: 18},
    {name: "Linfen", value: 17},
    {name: "Yiyang", value: 17},
    {name: "Puyang", value: 17},
    {name: "Yingkou", value: 16},
    {name: "Pingxiang", value: 16},
    {name: "Shuozhou", value: 16},
    {name: "Shenzhen", value: 16},
    {name: "Tieling", value: 15},
    {name: "Nyingchi", value: 15},
    {name: "Langfang", value: 15},
    {name: "Yunfu", value: 14},
    {name: "Dongfang", value: 14},
    {name: "Dalian", value: 14},
    {name: "Zhangjiakou", value: 14},
    {name: "Pu'er", value: 14},
    {name: "Wenshan", value: 14},
    {name: "Meishan", value: 13},
    {name: "Yingtan", value: 13},
    {name: "Jiaozuo", value: 13},
    {name: "Xiaogan", value: 13},
    {name: "Wanning", value: 12},
    {name: "Yulin", value: 12},
    {name: "Meizhou", value: 12},
    {name: "Zhanjiang", value: 11},
    {name: "Ezhou", value: 11},
    {name: "Qingyuan", value: 11},
    {name: "Sanmenxia", value: 11},
    {name: "Bayan Nur", value: 11},
    {name: "Hulunbuir", value: 11},
    {name: "Liupanshui", value: 10},
    {name: "Dehong", value: 10},
    {name: "Mudanjiang", value: 10},
    {name: "Fangchenggang", value: 10},
    {name: "Zhangye", value: 10},
    {name: "Luohe", value: 10},
    {name: "Dandong", value: 10},
    {name: "Huizhou", value: 10},
    {name: "Shaoguan", value: 10},
    {name: "Lishui", value: 10},
    {name: "Baicheng", value: 9},
    {name: "Jilin", value: 9},
    {name: "Liaoyang", value: 9},
    {name: "Fushun", value: 9},
    {name: "Tonghua", value: 9},
    {name: "Tunchang", value: 9},
    {name: "Qionghai", value: 9},
    {name: "Lingshui", value: 9},
    {name: "Daqing", value: 9},
    {name: "Benxi", value: 9},
    {name: "Dongguan", value: 9},
    {name: "Jixi", value: 9},
    {name: "Shaoxing", value: 8},
    {name: "Suizhou", value: 8},
    {name: "Anqing", value: 8},
    {name: "Huzhou", value: 8},
    {name: "Putian", value: 8},
    {name: "Weinan", value: 8},
    {name: "Chaozhou", value: 8},
    {name: "Aba", value: 8},
    {name: "Fuxin", value: 7},
    {name: "Xianning", value: 7},
    {name: "Ali", value: 7},
    {name: "Lijiang", value: 7},
    {name: "Anshun", value: 7},
    {name: "Panjin", value: 7},
    {name: "Yangquan", value: 7},
    {name: "Nantong", value: 7},
    {name: "Qianjiang", value: 6},
    {name: "Xinyu", value: 6},
    {name: "Bozhou", value: 5},
    {name: "Yangzhou", value: 5},
    {name: "Nanping", value: 5},
    {name: "Jiamusi", value: 5},
    {name: "Quanzhou", value: 5},
    {name: "Zhongshan", value: 5},
    {name: "Tianmen", value: 5},
    {name: "Lincang", value: 4},
    {name: "Qitaihe", value: 4},
    {name: "Xiangxi", value: 4},
    {name: "Xing'an League", value: 4},
    {name: "Jiaxing", value: 4},
    {name: "Yangjiang", value: 4},
    {name: "Daxing'anling", value: 4},
    {name: "Ding'an", value: 4},
    {name: "Sanming", value: 4},
    {name: "Shuangyashan", value: 4},
    {name: "Ledong", value: 4},
    {name: "Yi'chun", value: 4},
    {name: "Qinhuangdao", value: 4},
    {name: "Baishan", value: 3},
    {name: "Zhuhai", value: 3},
    {name: "Hebi", value: 3},
    {name: "Nujiang", value: 3},
    {name: "Xishuangbanna", value: 3},
    {name: "Sanya", value: 3},
    {name: "Qingyang", value: 3},
    {name: "Zhoushan", value: 3},
    {name: "Garze", value: 3},
    {name: "Panzhihua", value: 3},
    {name: "Wuxi", value: 3},
    {name: "Xilin Gol League", value: 3},
    {name: "Baoji", value: 3},
    {name: "Yancheng", value: 3},
    {name: "Yanbian", value: 3},
    {name: "Changjiang", value: 3},
    {name: "Jingdezhen", value: 3},
    {name: "Wenchang", value: 3},
    {name: "Weihai", value: 2},
    {name: "Shennongjia", value: 2},
    {name: "Bengbu", value: 2},
    {name: "Tai'zhou", value: 2},
    {name: "Dali", value: 2},
    {name: "Huaian", value: 2},
    {name: "Xiantao", value: 2},
    {name: "Yan'an", value: 2},
    {name: "Longnan", value: 2},
    {name: "Haixi", value: 2},
    {name: "Suzhou", value: 2},
    {name: "Hegang", value: 2},
    {name: "Dingxi", value: 2},
    {name: "Songyuan", value: 2},
    {name: "Zhenjiang", value: 2},
    {name: "Changzhou", value: 2},
    {name: "Wuhai", value: 2},
    {name: "Wuwei", value: 1},
    {name: "Hanzhou", value: 1},
    {name: "Hong Kong", value: 1},
    {name: "Kizilsu", value: 1},
    {name: "Zhangzhou", value: 1},
    {name: "Wuzhishan", value: 1},
    {name: "Wuhu", value: 1},
    {name: "Shizuishan", value: 1},
    {name: "Chuzhou", value: 1},
    {name: "Lu'an", value: 1},
    {name: "Jiuquan", value: 1},
    {name: "Guyuan", value: 1},
    {name: "Tongling", value: 1},
    {name: "Heihe", value: 1},
    {name: "Wuzhong", value: 1},
    {name: "Pingliang", value: 1},
    {name: "Alxa League", value: 1},
    {name: "Huainan", value: 1},
    {name: "Zhangjiajie", value: 1},
    {name: "Baisha", value: 1},
    {name: "Shihezi", value: 1}
]

export { dataZh, dataEn }