import React, { Component } from 'react';
import { Row, Col, Table, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import asyncFetch from '../../utils/asyncFetch';
import { _compartment } from './table_config';

const WithSubcellular = (props) => {
    let params = useParams();
    return <Subcellular {...props} params={params} />
}

class Subcellular extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            record: [],
            loading: true
        }
    }

    componentDidMount() {
        const { gene } = this.state;
        asyncFetch('GET', '/basic_table/compartment_data', {SYMBOL: gene, current_page: 1, page_size: 10000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    render() {
        const { record } = this.state;

        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Table
                            scroll={{x: 1200}}
                            columns={_compartment}
                            dataSource={record}
                        />
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default WithSubcellular