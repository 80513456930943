import { Tag } from 'antd';
import { Link } from 'react-router-dom';

const _variant = [
    {
        title: 'Gene',
        key: 'Gene',
        dataIndex: 'SYMBOL',
        fixed: 'left',
        render: (text) => {
            return (
                <i>
                    <Link style={{color: '#02709b'}} to={"/gene/" + text}>{ text }</Link>
                </i>
            )
        }
    }, {
        title: 'GRCh37',
        key: 'Variant 37',
        render: (record) => {
            let site = record.CHROM + '-' + record.POS + '-' + record.REF + '-' + record.ALT;
            return (
                <Link style={{color: '#02709b'}} to={"/variant/GRCh37/" + site}>{ site }</Link>
            )
        }
    },{
        title: 'GRCh38',
        key: 'Variant 38',
        render: (record) => {
            let site = record.CHROM_38 + '-' + record.POS_38 + '-' + record.REF + '-' + record.ALT;
            if (record.POS === record.CHROM_38) {
                return (
                    <Link style={{color: '#02709b'}} to={"/variant/GRCh38/" + site}>{ site }</Link>
                )
            } else {
                return (
                    <Link style={{color: '#02709b'}} to={"/variant/GRCh38/" + site}>{ site }</Link>
                )
            }
        }
    }, {
        title: 'dbSNP',
        key: 'dbSNP',
        dataIndex: 'dbSNP',
        render: (text) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }, {
        title: 'cDNA',
        key: 'cDNA',
        dataIndex: 'cDNA',
        render: (text) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }, {
        title: 'Protein',
        key: 'Protein',
        dataIndex: 'protein',
    }, {
        title: 'Consequence',
        key: 'Consequence',
        dataIndex: 'Consequence',
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _hpo = [
    {
        title: 'Gene',
        key: 'Gene',
        dataIndex: 'SYMBOL',
        width: 200,
        render: (text) => {
            return (
                <i>
                    <Link style={{color: '#02709b'}} to={"/gene/" + text}>{ text }</Link>
                </i>
            )
        }
    }, {
        title: 'HPO ID',
        key: 'HPO ID',
        dataIndex: 'Hpo_id',
        width: 200
    }, {
        title: 'Term Name',
        key: 'Term Name',
        dataIndex: 'Term_identifier'
    }, {
        title: 'Definition',
        key: 'Definition',
        dataIndex: 'Defination'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _disease = [
    {
        title: 'Gene',
        key: 'Gene',
        dataIndex: 'SYMBOL',
        width: 200,
        render: (text) => {
            return (
                <i>
                    <Link style={{color: '#02709b'}} to={"/gene/" + text}>{ text }</Link>
                </i>
            )
        }
    }, {
        title: 'Disease',
        key: 'Disease',
        dataIndex: 'Disease',
        width: 300,
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _gene = [
    {
        title: 'Gene',
        key: 'Gene',
        dataIndex: 'SYMBOL',
        width: 200,
        render: (text) => {
            return (
                <i>
                    <Link style={{color: '#02709b'}} to={"/gene/" + text}>{ text }</Link>
                </i>
            )
        }
    }, {
        title: 'Name',
        key: 'Name',
        dataIndex: 'Name'
    }, {
        title: 'Alias',
        key: 'alias',
        dataIndex: 'Previous_HGNC_symbols',
        render: (text) => {
            let list = text.split(';')
			let tags = []
			list.forEach((val, idx) => {
				tags.push(<Tag color="blue" style={{margin: '2px 2px'}} key={val+idx}>{val}</Tag>)
			})
			return (
				<i>
					{ tags }
				</i>
			)
        }
    }
]



export { _gene, _variant, _hpo, _disease };