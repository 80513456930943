import React from 'react';
import { Row, Col, Typography } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import lineBackground from '../../images/lineBackground.png';
import { dataZh, dataEn } from './province';
import { geoCoordMapZh, geoCoordMapEn } from './geoCoordMap';

require('../../configs/labs/china.js');

const { Title, Paragraph } = Typography;

const About = () => {
    const { t } = useTranslation();

    const d = t('lang') === 'CN' ? dataEn : dataZh
    const geoCoordMap = t('lang') === 'CN' ? geoCoordMapEn : geoCoordMapZh

    const converData = (d) => {
        let res = []
        d.forEach(val => {
            let geoCoord = geoCoordMap[val.name]
            if (geoCoord) {
                res.push({
                    name: val.name,
                    value: geoCoord.concat(val.value)
                })
            }
        })
        return res
    }

    const getOption = () => {
        return {
            grid: {
                left: 0,
                top: 0,
                bottom: 0
            },
            legend: {
                left: 'left',
                top: 'bottom',
                data: [
                    {
                        name: '1-500',
                        icon: 'circle',
                    }, {
                        name: '501-1000',
                        icon: 'circle',
                    }, {
                        name: '>1000',
                        icon: 'circle',
                    }
                ]
            },
            geo: [
                {
                    map: 'china',
                    label: {
                        show: false
                    },
                    itemStyle: {
                        areaColor: '#fff'
                    },
                    emphasis: {
                        disabled: true,
                        label: {
                            show: false
                        },
                        itemStyle: {
                            areaColor: '#fff'
                        }
                    }
                },
            ],
            series: [
                {
                    name: '1-500',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: converData(d.filter(val => val.value <= 500)),
                    symbolSize: 10,
                    encode: {
                        value: 2
                    },
                    itemStyle: {
                        color: '#13769A',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 16,
                            fontColor: '#000',
                            position: 'right',
                            formatter: (params) => {
                                return params.name + ', ' + params.value[2]
                            }
                        },
                    }
                }, {
                    name: '501-1000',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: converData(d.filter(val => val.value >= 501 && val.value <= 1000)),
                    symbolSize: 20,
                    encode: {
                        value: 2,
                    },
                    itemStyle: {
                        color: '#13769A',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 16,
                            fontColor: '#000',
                            position: 'right',
                            formatter: (params) => {
                                return params.name + ', ' + params.value[2]
                            }
                        },
                    }
                }, {
                    name: '>1000',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: converData(d.filter(val => val.value >= 1001)),
                    symbolSize: 30,
                    encode: {
                        value: 2,
                    },
                    itemStyle: {
                        color: '#13769A',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 16,
                            fontColor: '#000',
                            position: 'right',
                            formatter: (params) => {
                                return params.name + ', ' + params.value[2]
                            }
                        },
                    }
                }
            ]
        };
    };

    return (
        <Row justify='center'>
            <Col span={24} className="page-top-seize-seat" />
            <Col span={24}>
                <img src={lineBackground} className="line-background" alt="title background" />
                <Col span={20} offset={2} style={{marginTop: '6vh'}}>
                    <span className="page-title">
                        {t('about_cdgc')}
                    </span>
                </Col>
            </Col>
            <Col span={20}>
                <Row className="map-contain">
                    <Col span={24} className="page-description">
                        <Paragraph className="page-description-contain">
                            <div dangerouslySetInnerHTML={{ __html: t('about_us_description') }} />
                        </Paragraph>
                    </Col>
                    <Col span={24} className="map">
                        <Title level={2} className='page-description-title'>
                            {t('map_title')}
                        </Title>
                        <ReactEcharts option={getOption()} style={{minHeight: '900px'}}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default About