import React from 'react';
import { useRoutes } from 'react-router-dom';
import router from './routers';
import './App.css';

const App = () => {
    return (
        <>
            { useRoutes(router) }
        </>
    )
}

export default App;
