import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

const WithDensity = (props) => {
    let params = useParams();
    return <Density {...props} params={params} />
}

class Density extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            <Spin spinning={this.state.loading} className='gene-contain'>
                <img src={"https://cdn.genedock.com/img/xinan_public/plot/" + this.state.gene + ".png"} alt="density plot" width="100%"/>
            </Spin>
        )
    }
}

export default WithDensity