import React, { Component } from 'react';
import { Row, Col, Tabs, List, Badge, Table, message, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import asyncFetch from '../../utils/asyncFetch';
import { _omim, _hpo, _mgi } from './table_config';

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const WithPheontype = (props) => {
    let params = useParams();
    return <Phenotype {...props} params={params} />
};
const CIDetail = ({ ciDetail }) => {
    const { CI, Reference } = ciDetail;
    
    if (!Reference || typeof Reference !== 'string') {
        return null;
    }

    const references = Reference.split('#');

    return (
        <div>
            <Paragraph>
                {/* <span dangerouslySetInnerHTML={{ __html: convertToSuperscript(CI) }} /> */}
                {CI}
                <br />
                <Text type="warning">[After the xxxx article is published, you can view the complete content.]</Text>
            </Paragraph>
            <ul>
                {references.map((ref, index) => (
                    <li key={index}>{ref.trim()}</li>
                ))}
            </ul>
        </div>
    )
}

class Phenotype extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            record: [],
            loading: true
        }
    }

    componentDidMount() {
        this.handleLoad('ghr_data');
    };

    handleLoad = (item) => {
        asyncFetch('GET', '/basic_table/' + item, {SYMBOL: this.state.gene, current_page: 1, page_size: 10000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    onChange = (key) => {
        let colletction = 'ghr_data'
        switch(key) {
            case 'ghr':
                break;
            case 'omim':
                colletction = 'omim_data';
                break;
            case 'hpo':
                colletction = 'hpo_data';
                break;
            case 'mgi':
                colletction = 'mgi_data';
                break;
            case 'ci':
                colletction = 'ci_data';
                break;
            default:
                break;
        }
        this.setState({
            loading: true
        })
        this.handleLoad(colletction);
    };

    render() {
        const { record, loading } = this.state;
        
        return (
            <Row>
                <Col span={24}>
                    <Spin spinning={loading}> 
                        <Tabs defaultActiveKey='ghr' onChange={this.onChange}>
                            <TabPane tab="Genetic Home Reference" key="ghr">
                                <List
                                    itemLayout='horizontal'
                                    dataSource={record}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <>
                                                        <Badge status="processing" text={item.Disease} />
                                                    </>
                                                }
                                                description={item.Disease_description}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </TabPane>
                            <TabPane tab="OMIM" key="omim">
                                <Table
                                    bordered
                                    scroll={{x: 1200}}
                                    columns={_omim}
                                    dataSource={record}
                                />
                            </TabPane>
                            <TabPane tab="Human Phenotype Ontology" key="hpo">
                                <Table
                                    scroll={{x: 1200}}
                                    columns={_hpo}
                                    dataSource={record}
                                />
                            </TabPane>
                            <TabPane tab="Mouse Genome Informatics" key="mgi">
                                <Table
                                    scroll={{x: 1200}}
                                    columns={_mgi}
                                    dataSource={record}
                                />
                            </TabPane>
                            <TabPane tab="Outcome of Cochlear Implantation" key="ci">
                                {record.length > 0 && <CIDetail ciDetail={record[0]} />}
                            </TabPane>
                        </Tabs>
                    </Spin>
                </Col>
            </Row>
        )
    }
}

export default WithPheontype