import React from 'react';
import { Row, Col, Typography } from 'antd';

import HomeSearch from './images/home_search.jpg';
import HomeSearchExample from './images/home_search_example.jpg';
import GeneInformation from './images/gene_information.jpg';
import GeneVariant from './images/gene_variant.jpg';
import GenePhenotype from './images/gene_phenotype.jpg';
import GeneMissense from './images/gene_missense.jpg';
import GeneFunctionTabel from './images/gene_function_table.jpg';
import GeneFunctionPicture from './images/gene_function_picture.jpg';
import GenePathway from './images/gene_pathway.jpg';
import GeneExpression from './images/gene_expression.jpg';
import GeneSubcellular from './images/gene_subcellular.jpg';
import GeneDomain from './images/gene_domain.png';
import GeneDrug from './images/gene_drug.jpg';
import Browse from './images/browse.jpg';

const { Title, Paragraph, Text } = Typography;

const Tutorial = () => {
    return (
        <Row justify="center">
            <Col span={24} className="page-top-seize-seat" />
            <Col span={20} style={{ fontSize: '16px' }}>
                <Typography>
                    <Title>Quick search</Title>
                    <Paragraph>
                        Users can quickly access the genetic and clinical data of GDC by employing the tool of quick search at home page. Our website can recognize a variety of key terms when they are typed in search box.
                    </Paragraph>
                    <Paragraph>
                        For quick search in the home page, users can identify their information of interest by entering one of the following:
                    </Paragraph>
                    <Paragraph>
                        <ul style={{ marginLeft: '40px' }}>
                            <li>
                                <Text>gene symbol: such as <i>GJB2</i></Text>
                            </li>
                            <li>
                                <Text>genomic region: chromosome-start-end, such as 13-20189473-C-T</Text>
                            </li>
                            <li>
                                <Text>Phenotype term: such as Abnormality of body height</Text>
                            </li>
                            <li>
                                <Text>Disease: such as Usher Syndrome Type 1</Text>
                            </li>
                        </ul>
                        <img style={{ maxWidth: '100%' }} src={HomeSearch} alt='HomeSearch' />
                    </Paragraph>
                    <Paragraph>
                        An example search result is shown below:
                        <img style={{ maxWidth: '100%' }} src={HomeSearchExample} alt='HomeSearchExample' />
                        If you want to know more about the gene, you can click on the corresponding gene name to go to the detailed information page.
                    </Paragraph>
                    <Paragraph>
                        This page integrates 11 gene-related sections showing basic information about genes, phenotype and disease, variation in different populations, function, expression, domain, subcellular localization, description of drug-gene interactions, cochlear implant results, RG results, and enrichment results of disease-causing sites for Missense. Each part represents different biological information.
                    </Paragraph>
                    <Title>Basic information</Title>
                    <Paragraph>
                        This section integrates the basic information of corresponding gene that includes the official symbol, official full name, location, synonyms, qucik links from different databases, gene summary.
                        <img style={{ maxWidth: '100%' }} src={GeneInformation} alt='GeneInformation' />
                    </Paragraph>
                    <Title>Variant search</Title>
                    <Paragraph>
                        This section provides all the variants information related to genes, and users can search according to their needs.
                        <img style={{ maxWidth: '100%' }} src={GeneVariant} alt='GeneVariant' />
                    </Paragraph>
                    <Title>Phenotype</Title>
                    <Paragraph>
                        This section provides annotated information about gene function according to Genetic Home Reference,OMIM, HPO, MGI and CI.
                        <img style={{ maxWidth: '100%' }} src={GenePhenotype} alt='GenePhenotype' />
                    </Paragraph>
                    <Title>Missense variant density</Title>
                    <Paragraph>
                        This section provides frequency information of all missense variants in the gene, so that users can easily view the protein structure location where the mutation information is located.
                        <img style={{ maxWidth: '100%' }} src={GeneMissense} alt='GeneMissense' />
                    </Paragraph>
                    <Title>Gene function</Title>
                    <Paragraph>
                        This section provides molecular function from biology process ontologies visualized by the Gene Ontology Consortium.
                        <img style={{ maxWidth: '100%' }} src={GeneFunctionTabel} alt='GeneFunctionTable' />
                        <img style={{ maxWidth: '100%' }} src={GeneFunctionPicture} alt='GeneFunctionPicture' />
                    </Paragraph>
                    <Title>Pathway</Title>
                    <Paragraph>
                        This section provides pathway from NCBI PubChem.
                        <img style={{ maxWidth: '100%' }} src={GenePathway} alt='GenePathway' />
                    </Paragraph>
                    <Title>Gene expression</Title>
                    <Paragraph>
                        This section contains expression images from GTEx and scRNA from mouse cochlea.
                        <img style={{ maxWidth: '100%' }} src={GeneExpression} alt='GeneExpression' />
                    </Paragraph>
                    <Title>Subcellular</Title>
                    <Paragraph>
                        This section provides subcellular location from from COMPARTMENTS.
                        <img style={{ maxWidth: '100%' }} src={GeneSubcellular} alt='GeneSubcellular' />
                    </Paragraph>
                    <Title>Domains</Title>
                    <Paragraph>
                        This section provides protein domain from InterPro.
                        <img style={{ maxWidth: '100%' }} src={GeneDomain} alt='GeneDomain' />
                    </Paragraph>
                    <Title>Interaction</Title>
                    <Paragraph>
                        This section provides protein-protein interactions from InBio Map.
                    </Paragraph>
                    <Title>Drug-gene interaction</Title>
                    <Paragraph>
                        This section provides the relationships between genes and drugs from DGIdb. It contains drug-gene interactions and gene drug ability for precision medicine.
                        <img style={{ maxWidth: '100%' }} src={GeneDrug} alt='GeneDrug' />
                    </Paragraph>
                    <Title>Browse</Title>
                    <Paragraph>
                        To facilitate users to browse the data in GDC, genes are indexed by alphabet.
                        <img style={{ maxWidth: '100%' }} src={Browse} alt='Browse' />
                    </Paragraph>
                </Typography>
            </Col>
        </Row>
    )
}

export default Tutorial