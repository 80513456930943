import React, { useState, useRef } from 'react';
import { Row, Col, Input, Table, Result, message } from 'antd';
import asyncFetch from '../../utils/asyncFetch';
import { columns } from './columns';
import { useTranslation } from 'react-i18next';

import noResult from '../../images/noResult.png';

const { Search } = Input;

const Tool = () => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState([]);
    const [visiable, setVisiable] = useState(false); 

    const chromRef = useRef(null);
    const positionRef = useRef(null);
    const referenceRef = useRef(null);

    const null_undefault = (value) => {
        if (typeof(value) === "undefined" || value === "") {
            return false
        } else {
            return true
        }
    };

    const onSearch = (value) => {
        let chrom = chromRef.current.input.value,
        pos = positionRef.current.input.value,
        ref = referenceRef.current.input.value,
        alt = value;
        if(null_undefault(chrom) & null_undefault(pos) & null_undefault(ref) & null_undefault(alt)) {
            setLoading(false);
            loadData(chrom, pos, ref, alt);
        } else {
            message.error({
                content: 'Site info cannot be empty!'
            })
        }
    };

    const loadData = (chrom, pos, ref, alt) => {
        const key = 'loading';
        message.loading({content: 'Loading...', key});
        asyncFetch('GET', '/dvpred', {CHROM: chrom, POS: pos, REF: ref, ALT: alt},
            (res) => {
                if(res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx;
                        result.push(val)
                    });
                    setTimeout(() => {
                        message.success({content: 'Loaded!', key, duration: 2})
                        setLoading(true);
                        setRecord(result);
                        setVisiable(result.length > 0 ? true : false);
                    }, 1000);
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    };

    const handleClick = (e) => {
        let site = e.target.innerHTML, array = site.split('-');
        loadData(array[0], array[1], array[2], array[3]);
    };

    return (
        <Row>
            <Col span={24} className="page-top-seize-seat" />
            <Col span={24} className="tool-center dvpred-contain">
                <span className="dvpred-title">The Deafness Variant Function Prediction(DVPred) Tool</span>
                <p className="dvpred-description">
                    {t('tool_description')}
                </p>
                <Col span={24}>
                    <Input.Group compact style={{margin: '5.5vh 0 3.5vh 0'}}>
                        <Input
                            style={{
                                width: '20vh',
                                height: '60px',
                                borderRadius: '6px 0 0 6px',
                                border: 'none'
                            }}
                            placeholder="CHROM"
                            ref={chromRef}
                        />
                        <Input
                            style={{
                                width: '5vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="-"
                            disabled
                            className=""
                        />
                        <Input
                            style={{
                                width: '20vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="POS"
                            ref={positionRef}
                        />
                        <Input
                            style={{
                                width: '5vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="-"
                            disabled
                        />
                        <Input
                            style={{
                                width: '20vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="REF"
                            ref={referenceRef}
                        />
                        <Input
                            style={{
                                width: '5vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="-"
                            disabled
                        />
                        <Search
                            style={{
                                width: '20vh',
                                height: '60px',
                                border: 'none'
                            }}
                            placeholder="ALT"
                            size="large"
                            onSearch={onSearch}
                        />
                    </Input.Group>
                </Col>
                Example: 
                <span onClick={handleClick} style={{cursor: 'pointer'}}>16-2550805-G-C</span>
            </Col>
            <Col span={24} className="tool-bottom">
                {
                    visiable ? (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={record}
                            style={{display: loading ? '' : 'none'}}
                            pagination={false}
                            scroll={{ x: 'max-content' }}
                        />
                    ) : (
                        <Result
                            icon={<img src={noResult} alt="Not Data" />}
                            title="Sorry! No relevant data found"
                            style={{display: loading ? '' : 'none'}}
                        />
                    )
                }
            </Col>
        </Row>
    )
}

export default Tool