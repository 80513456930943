import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Table, Space, Rate, message, Result, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VariantIcon from '../../images/Variant.png';
import PathogenicityIcon from '../../images/Pathogenicity.png';
import SoftwareIcon from '../../images/Software.png';
import FrequenciesIcon from '../../images/Frequencies.png';
import PhenotypeIcon from '../../images/Phenotype.png';
import ReferenceIcon from '../../images/Reference.png';

import asyncFetch from '../../utils/asyncFetch';
import ThridText from '../../components/ThridText';

const { Title } = Typography;

const splitTranscript = (obj, key) => {
    if (typeof(obj) === 'undefined') {
        return []
    }
    if (obj.length === 0) {
        return []
    }
    let item = obj[key] || ""
    return item.split("|")
}

const handleAFValue = (key, item, obj) => {
    if (key === 'gnomAD') {
        return item === '' ? obj[key + '_AF'] : obj[key + '_AF_' + item]
    } else {
        return 
    }
}

const handleData = (obj) => {
    let source = ['gnomAD', '1KGP'], items = [], datas = [];
    source.forEach((val, idx) => {
        Object.keys(obj).forEach((v) => {
            if (v.startsWith(val)) {
                let _v = v.replace(/gnomAD_|1KGP_|AC_|AN_|AF_|MAF_|Hom_/g, '')
                items.push(_v)
            }
        })
        items = [...new Set(items)]
        items = items.filter((item) => { return item !== "AF" && item !== "AN" && item !== "AC" && item !== "Hemi" && item !== "Hom" })
        if (val === 'gnomAD' | val === '1KGP') {
            items = ['', ...items]
        }
        items.forEach((Val, Idx) => {
            if (Idx === 0) {
                datas.push(
                    {
                        key: idx + 1,
                        source: val,
                        population: Val.toUpperCase(),
                        count: Val === '' ? obj[val + '_AC'] : obj[val + '_AC_' + Val],
                        number: Val === '' ? obj[val + '_AN'] : obj[val + '_AN_' + Val],
                        frequency: handleAFValue(val, Val, obj),
                        homo: Val === '' ? obj[val + '_Hom'] : obj[val + '_Hom_' + Val],
                        children: []
                    }
                )
            } else {
                datas[idx].children.push(
                    {
                        key: (idx + 1) * 10 + Idx,
                        population: Val.toUpperCase(),
                        count: obj[val + '_AC_' + Val],
                        number: obj[val + '_AN_' + Val],
                        frequency: handleAFValue(val, Val, obj),
                        homo: obj[val + '_Hom_' + Val],
                    }
                )
            }
        })
        items = []
    })

    datas.push(
        {
            key: 4,
            source: 'ChinaMAP',
            population: null,
            count: obj['ChinaMAP_AC'],
            number: obj['ChinaMAP_AN'],
            frequency: obj['ChinaAMP_AF'],
            homo: null,
        }, {
            key: 5,
            source: 'BBJ',
            population: null,
            count: obj['BBJ_AC'],
            number: obj['BBJ_AN'],
            frequency: obj['BBJ_AF'],
            homo: null,
        }, {
            key: 6,
            source: 'WBBC',
            population: null,
            count: obj['WBBC_AC'],
            number: obj['WBBC_AN'],
            frequency: obj['WBBC_AF'],
            homo: null,
        }
    )
    return datas
}

const formatPubmed = (val) => {
    const data = (val.pub_hgmd + "|" + val.pub_dvd).split("|")
    var res = [], obj = {}
    for (var i = 0; i < data.length; i++) {
        if (!obj[i]) {
            obj[i] = 1;
            res.push(data[i])
        }
    }
    return res.join(",")
}

const Variant = () => {
    const params = useParams();
    const { t } = useTranslation();
    const [version] = useState(params.version || '');
    const [site] = useState(params.site || '');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const s = site.split(/[>|:|-]/);
        const param = {
            CHROM: s[0] || '',
            POS: s[1] || '',
            REF: s[2] || '',
            ALT: s[3] || ''
        }
        asyncFetch('GET', '/variant/' + version, param,
            (res) => {
                if (res.code === 200) {
                    if (res.data.length > 0) {
                        setData(res.data[0]);
                    }
                    setLoading(false);
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }, [site, version]);

    const allConsequence = splitTranscript(data, 'all_consequence');
    const allTranscript = splitTranscript(data, 'all_transcript');
    const allCDNA = splitTranscript(data, 'all_c');
    const allProtein = splitTranscript(data, 'all_p');
    let record = []
    allConsequence.forEach((val, idx) => {
        record.push(
            {
                key: idx,
                transcript: allTranscript[idx],
                cDNA: allCDNA[idx],
                protein: allProtein[idx],
                consequence: val,
                label: allTranscript[idx] === data.transcript ? 1 : 0
            }
        )
    })
    return (
        <Spin spinning={loading}>
            <Row style={{width: '100%'}}>
                <Col span={24} className='page-top-seize-seat' />
                    {
                        loading === false && data.length === 0 ? (
                        <Result
                            style={{margin: 'auto'}}
                            status='info'
                            title='No Data'
                        />) :
                        (<Col span={24} className='variant-contain'>
                            <Title level={3}>
                                <img src={VariantIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{params.site || ''}
                            </Title>
                            <Row>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <span className='variant-thrid-title'>
                                        Gene:
                                    </span>
                                    <i>
                                        <ThridText contact={data?.SYMBOL} color="#02709b" background="#cbe5f0" link={true} url={"/gene/"} />
                                    </i>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <span className='variant-thrid-title'>
                                        dbSNP:
                                    </span>
                                    <ThridText contact={data?.dbSNP} color="#e64545" background="#FFDDDD" link={true} url={"https://www.ncbi.nlm.nih.gov/snp/"} />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <span className='variant-thrid-title'>
                                        Genomic Position (GRCh37):
                                    </span>
                                    <span style={{ color: "#f27522", background: "#FFF1DD", overflowWrap: 'break-word' }}>
                                        <a href={"https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg19&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=chr" + data?.CHROM + ':' + (data?.POS - 25) + '-' + (data?.POS + 25)} alt="" target='_blank' rel="noreferrer" style={{ color: "#f27522" }}>{`${data?.CHROM}: ${data?.POS}`}</a>
                                    </span>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <span className='variant-thrid-title'>
                                        Genomic Position (GRCh38):
                                    </span>
                                    <span style={{ color: "#15a574", background: "#D0EDE3", overflowWrap: 'break-word' }}>
                                        <a href={"https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=chr" + data?.CHROM_38 + ':' + (data?.POS_38 - 25) + '-' + (data?.POS_38 + 25)} alt="" target='_blank' rel="noreferrer" style={{ color: "#15a574" }}>{`${data?.CHROM_38}: ${data?.POS_38}`}</a>
                                    </span>
                                </Col>
                            </Row>
                            <Table
                                style={{marginTop: '50px'}}
                                columns={[
                                    {
                                        dataIndex: 'transcript',
                                        title: 'Transcript',
                                        width: '300px',
                                        render: (text, record) => {
                                            if (record.label === 1) {
                                                return (
                                                    <Space>
                                                        <ThridText contact={text} color="#000000" background="" link={true} url={"https://genome.ucsc.edu/cgi-bin/hgSearch?search="} supple='&db=hg38' />
                                                        <Rate count={1} disabled defaultValue={1} />
                                                    </Space>
                                                )
                                            } else {
                                                return <ThridText contact={text} color="#000000" background="" link={true} url={"https://genome.ucsc.edu/cgi-bin/hgSearch?search="} supple='&db=hg38' />
                                            }
                                        }
                                    },
                                    {
                                        dataIndex: 'cDNA',
                                        title: 'cDNA',
                                        width: '300px'
                                    },
                                    {
                                        dataIndex: 'protein',
                                        title: 'Protein',
                                        width: '300px'
                                    },
                                    {
                                        dataIndex: 'consequence',
                                        title: 'Consequence',
                                        width: '300px'
                                    }
                                ]
                                .map(c => Object.assign(c, {
                                    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
                                    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#02709b', color: '#fff'}})
                                }))
                                }
                                dataSource={record}
                                pagination={false}
                                bordered
                                scroll={{ x: 'max-content' }}
                            />
                            <Row style={{marginTop: '30px'}}>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 12 }}
                                >
                                    <Title level={3}>
                                        <img src={PathogenicityIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{t('pathogenicity_source')}
                                    </Title>
                                    <Col span={24}>
                                        <span className='variant-thrid-title'>
                                            CDGC:
                                        </span>
                                        <ThridText contact={data?.CDGC_diagnosis || "NA"} color="#02709b" background="#fff" />
                                    </Col>
                                    <Col span={24}>
                                        <span className='variant-thrid-title'>
                                            HGMD:
                                        </span>
                                        <ThridText contact={data?.HGMD_Variantclass || "NA"} color="#02709b" background="#fff" />
                                    </Col>
                                    <Col span={24}>
                                        <span className='variant-thrid-title'>
                                            ClinVar:
                                        </span>
                                        <ThridText contact={data?.Clinvar_CLNSIG || "NA"} color="#02709b" background="#fff" />
                                    </Col>
                                    <Col span={24}>
                                        <span className='variant-thrid-title'>
                                            DVD:
                                        </span>
                                        <ThridText contact={data?.DVD_pathogenicity || "NA"} color="#02709b" background="#fff" />
                                    </Col>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 12 }}
                                >
                                    <Title level={3}>
                                        <img src={SoftwareIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{t('predication_software')}
                                    </Title>
                                    <Row>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                DVPred:
                                            </span>
                                            <ThridText contact={data?.DVPred || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                CADD:
                                            </span>
                                            <ThridText contact={data?.CADD_PHRED || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                MCAP:
                                            </span>
                                            <ThridText contact={data?.['M-CAP_score'] || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                SIFT:
                                            </span>
                                            <ThridText contact={data?.SIFT || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                REVEL:
                                            </span>
                                            <ThridText contact={data?.REVEL_RAW || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                POLYPHEN:
                                            </span>
                                            <ThridText contact={data?.PolyPhen || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                GERP:
                                            </span>
                                            <ThridText contact={data?.['GERP++_score'] || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                        <Col span={12}>
                                            <span className='variant-thrid-title'>
                                                Phylop:
                                            </span>
                                            <ThridText contact={data?.phyloP100way_vertebrate || "NA"} color="#02709b" background="#fff" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '30px'}}>
                                <Title level={3}>
                                    <img src={FrequenciesIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{t('frequencies')}
                                </Title>
                                <Col span={24}>
                                    <Table
                                        columns={[
                                            {
                                                title: 'Source',
                                                dataIndex: 'source',
                                                key: 'source',
                                            },
                                            {
                                                title: 'Population',
                                                dataIndex: 'population',
                                                key: 'population',
                                            },
                                            {
                                                title: 'Allele Count',
                                                dataIndex: 'count',
                                                key: 'count',
                                            },
                                            {
                                                title: 'Allele Number',
                                                dataIndex: 'number',
                                                key: 'number',
                                            },
                                            {
                                                title: 'Allele Frequency',
                                                dataIndex: 'frequency',
                                                key: 'frequency',
                                            },
                                            {
                                                title: 'Number of Homozygotes',
                                                dataIndex: 'homo',
                                                key: 'homo',
                                            },
                                        ]}
                                        dataSource={handleData(data)}
                                        scroll={{ x: 'max-content' }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '30px'}}>
                                <Title level={3}>
                                    <img src={PhenotypeIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{t('phenotype')}
                                </Title>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        HGMD:
                                    </span>
                                    <ThridText contact={data?.Phenotype_hgmd || "NA"} color="#02709b" background="#fff" />
                                </Col>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        Clinvar:
                                    </span>
                                    <ThridText contact={data?.Phenotype_clinvar || "NA"} color="#02709b" background="#fff" />
                                </Col>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        DVD:
                                    </span>
                                    <ThridText contact={data?.Phenotype_dvd || "NA"} color="#02709b" background="#fff" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '30px'}}>
                                <Title level={3}>
                                    <img src={ReferenceIcon} alt="variant icon" style={{padding: '9.5px 6px'}}/>{t('reference')}
                                </Title>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        PubMed:
                                    </span>
                                    <ThridText contact={formatPubmed(data)} color="#02709b" background="#fff" link={true} url="https://www.ncbi.nlm.nih.gov/pubmed/" />
                                </Col>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        LitVar:
                                    </span>
                                    <ThridText contact={data?.dbSNP} color="#02709b" background="#fff" link={true} url="https://www.ncbi.nlm.nih.gov/research/litvar2/docsum?variant=litvar@" supple={`%23%23&query=${data?.dbSNP}`} />
                                </Col>
                                {/* <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        BEST:
                                    </span>
                                    <i>
                                        <ThridText contact={data?.SYMBOL} color="#02709b" background="#fff" link={true} url="http://best.korea.ac.kr/s?otype=8&q=" supple="&t=l&wt=xslt&tr=l.xsl" />
                                    </i>
                                </Col>
                                <Col span={24}>
                                    <span className='variant-thrid-title'>
                                        Variant2Literature:
                                    </span>
                                    <ThridText contact={data?.dbSNP} color="#02709b" background="#fff" link={true} url="https://www.taigenomics.com/console/v2l/search/variant/default/0/" supple="/0/1" />
                                </Col> */}
                            </Row>
                        </Col>)
                    }
            </Row>
        </Spin>
    )
}

export default Variant