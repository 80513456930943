const ThridText = ({contact, color, background, link, url, supple=""}) => {
    if (link) {
        return (
            <span style={{color: color, background: background, overflowWrap: 'break-word'}}>
                <a href={url + contact + supple} alt="pubmed" style={{color: color}} target="_black">{contact}</a>
            </span>
        )
    } else {
        return (
            <span style={{color: color, background: background, overflowWrap: 'break-word'}}>
                {contact}
            </span>
        )
    }
}

export default ThridText