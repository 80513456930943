import React from 'react';
import { Row, Col, Card, Button, Table, message } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import asyncFetch from '../../utils/asyncFetch';

import { database_columns, database_source } from './table_config';
import dna from '../../images/dna.png';
import topoloy from '../../images/topoloy.png';
import flask from '../../images/flask.png';

const Download = () => {
    const download = (fileType) => {
        const key = 'loading';
        message.loading({content: 'Loading...', key});
        let download_filename = "";
        if (fileType === "gdc_variant") {
            download_filename = "all_gdc_variant.gz";
        } else if (fileType === "gdc_gene") {
            download_filename = "all_gdc_gene.7z";
        } else {
            download_filename = "all_dvpred_variant.gz";
        }
        asyncFetch('GET', '/download', {filetype: fileType},
            (res) => res.blob().then(
                blob => {
                    message.success({content: 'Loaded!', key, duration: 2})
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.download = download_filename;
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href)
                    document.body.removeChild(link)
                }
            ),
        {}, 'cors', {}, true);
    }

    return (
        <Row>
            <Col span={24} className="page-top-seize-seat" />
            <Col span={24} className="download-center download-contain">
                <span className="dvpred-title download-title">DOWNLOAD</span>
                <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: '2vh'}}>
                    <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                        <Card title="All GDC Variants" bordered={false} className="download-item" style={{minHeight: '266px'}}>
                            <div className="circle" />
                            <img src={dna} alt="dna" className="download-item-icon" />
                            <span className="download-item-date">July 30th, 2024</span>
                            <p className="download-item-description">
                                This file contains all annotated variants in the GDC. Due to the internal data usage of the Chinese Deafness Genetics Consortium (CDGC), the download will be available upon the publication of the CDGC manuscript.
                            </p>
                            <Row className="download-item-bottom">
                                <Col span={4}>613M</Col>
                                <Col span={20} style={{textAlign: 'right'}}>
                                    <Button icon={<ArrowDownOutlined />} className="download-btn" onClick={() => download("gdc_variant")}>Download</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                        <Card title="All GDC Genes" bordered={false} className="download-item" style={{minHeight: '266px'}}>
                            <div className="circle" />
                            <img src={topoloy} alt="topoloy" className="download-item-icon" />
                            <span className="download-item-date">July 30th, 2024</span>
                            <p className="download-item-description">
                                This file contains all genes in the GDC.
                            </p>
                            <Row className="download-item-bottom">
                                <Col span={4}>34KB</Col>
                                <Col span={20} style={{textAlign: 'right'}}>
                                    <Button icon={<ArrowDownOutlined />} className="download-btn" onClick={() => download("gdc_gene")}>Download</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                        <Card title="All DVPred Variants" bordered={false} className="download-item" style={{minHeight: '266px'}}>
                            <div className="circle" />
                            <img src={flask} alt="flask" className="download-item-icon" />
                            <span className="download-item-date">July 30th, 2024</span>
                            <p className="download-item-description">
                                This file contains the site information of DvPred prediction results.
                            </p>
                            <Row className="download-item-bottom">
                                <Col span={4}>18.3M</Col>
                                <Col span={20} style={{textAlign: 'right'}}>
                                    <Button icon={<ArrowDownOutlined />} className="download-btn" onClick={() => download("dvpred_variant")}>Download</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="download-contain">
                <span className="download-contain ant-card-head-title">PUBLIC DATA</span>
                <Table
                    bordered={false}
                    columns={database_columns}
                    dataSource={database_source}
                    scroll={{ x: 'max-content' }}
                />
            </Col>
        </Row>
    )
}

export default Download