import React, { Component } from 'react';
import { message, Spin, Radio } from 'antd';
import { useParams } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import asyncFetch from '../../utils/asyncFetch';

const WithExpression = (props) => {
    let params = useParams();
    return <Expression {...props} params={params} />
}

function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const plainOptions = ['Homo sapiens', 'Mus musculus', 'Macaca mulatta'];

const IMAGE_ROOT_URL = '/gene_expression_single_cell/';
const IMAGE_MMul_URL = '/gene_expression_monkey/';



class Expression extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            data: [],
            loading: true,
            value: 'Homo sapiens'
        }
    }

    componentDidMount() {
        const { gene } = this.state;

        asyncFetch('GET', '/expression', {SYMBOL: gene},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        data: result,
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    getOption() {
        const { gene, data } = this.state;
        let d = data.map(function(val, idx) {
            return [val['rownames'], +val[gene]];
        });
        return {
            grid: {
                left: 70,
                right: 70,
                bottom: 200
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    interval: 0,
                    rotate: -45
                }
            },
            yAxis: {
                type: 'value',
                name: 'TMP',
                nameTextStyle: {
                    fontSize: 16,
                    fontWeight: 'bold'
                },
                nameLocation: 'middle',
                nameGap: 50,
                axisLine: {
                    show: true
                }
            },
            series: [
                {
                    name: 'scatter',
                    type: 'scatter',
                    symbolSize: 2,
                    data: d
                }
            ]
        };
    }

    onChange = ({ target: { value } }) => {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                value,
                loading: false
            });
        }, 1000);
    }

    render() {
        const { gene, value } = this.state;

        const imageUrl = IMAGE_ROOT_URL + capitalizeFirstLetter(gene) + "_umap.png";
        const imggeMul = IMAGE_MMul_URL + gene.toUpperCase() + ".png";

        let componetToRender;
        if (value === 'Homo sapiens') {
            componetToRender = <ReactEcharts option={this.getOption()} style={{weight: '100%', height: '700px'}} />
        } else if (value === 'Mus musculus') {
            componetToRender = <img src={imageUrl} width="100%" alt="Gene Expression" />
        } else {
            componetToRender = <img src={imggeMul} width="100%" alt="Gene Expression" />
        }

        return (
            <Spin spinning={this.state.loading}>
                <Radio.Group options={plainOptions} onChange={this.onChange} value={value} optionType="button" />
                { componetToRender }
            </Spin>
        )
    }
}

export default WithExpression