import React, { Component } from 'react';
import { Row, Col, message, Divider } from 'antd';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import asyncFetch from '../../utils/asyncFetch';

const WithGene = (props) => {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    const {t} = useTranslation();
    return <Gene {...props} params={params} navigate={navigate} location={location} t={t} />
}

class Gene extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
        }
    }

    componentDidMount() {
        this.handleLoad()
        let pathArray = this.props.location.pathname.split('/');
        let key = pathArray.length === 4 ? pathArray[3] : "";
        this.addActivate(key)
    }

    addActivate = (key) => {
        let geneItem = document.getElementsByClassName('gene-item-tabs')
        for (let i = 0; i < geneItem.length; i++) {
            geneItem[i].classList.remove('gene-item-tabs-active')
        }
        for (let i = 0; i < geneItem.length; i++) {
            if (geneItem[i].getAttribute('id') === key) {
                geneItem[i].classList.add('gene-item-tabs-active')
            }
        }
    }

    handleLoad = () => {
        const { gene} = this.state;
        this.loadData(gene);
    }

    loadData = (gene) => {
        asyncFetch('GET', '/basic_table/gene_data', {SYMBOL: gene, current_page: 1, page_size: 1},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }

    tabsClick = (e) => {
        const itemKey = e.target.id === "" ? "" : "/" + e.target.id
        this.props.navigate('/gene/' + this.state.gene + itemKey)
        this.addActivate(e.target.id)
    }

    render() {
        const { gene } = this.state;
        const t = this.props.t;
        return (
            <Row style={{width: '100%'}}>
                <Col span={24} className='page-top-seize-seat' />
                <Col span={24} className='gene-contain'>
                    <Col span={24}>
                        <div className='gene-title-mark' />
                        <span className='gene-title-contain'>
                            {gene}
                        </span>
                    </Col>
                    <Row gutter={[16, 16]} className='gene-tabs-contain'>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="">
                                {t('gene_item_basic')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="variant">
                                {t('gene_item_variant')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="phenotype">
                                {t('gene_item_phenotype')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="density">
                                {t('gene_item_density')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="function">
                                {t('gene_item_function')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="pathway">
                                {t('gene_item_pathway')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="expression">
                                {t('gene_item_expression')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="subcellular">
                                {t('gene_item_subcellular')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="domain">
                                {t('gene_item_domain')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="interaction">
                                {t('gene_item_interaction')}
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                            <div className='gene-item-tabs' onClick={(e) => this.tabsClick(e)} id="drug">
                                {t('gene_item_drug')}
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div>
                        <Outlet />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default WithGene