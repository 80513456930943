import { Rate, Tag } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function database(val) {
    if (val === "") {
        return "-"
    } else if (val.toUpperCase().indexOf("V") !== -1) {
        return (
            <Tag color="#c0c0c0"><strong>VUS</strong></Tag>
        )
    } else if (val.toUpperCase().indexOf("B") !== -1) {
        return (
            <Tag color="blue"><strong>{val.toUpperCase()}</strong></Tag>
        )
    } else if (val.toUpperCase().indexOf("P") !== -1) {
        return (
            <Tag color="red"><strong>{val.toUpperCase()}</strong></Tag>
        )
    } else {
        return val
    }
}

const _variant = [
    {
        title: 'GRCh37',
        key: 'Variant 37',
        width: 200,
        fixed: 'left',
        render: (record) => {
            let site = record.CHROM + '-' + record.POS + '-' + record.REF + '-' + record.ALT;
            return (
                <Link style={{color: '#02709b'}} to={"/variant/GRCh37/" + site}>{ site }</Link>
            )
        }
    },{
        title: 'GRCh38',
        key: 'Variant 38',
        width: 200,
        render: (record) => {
            let site = record.CHROM_38 + '-' + record.POS_38 + '-' + record.REF + '-' + record.ALT;
            if (record.POS === record.CHROM_38) {
                return (
                    <Link style={{color: '#02709b'}} to={"/variant/GRCh38/" + site}>{ site }</Link>
                )
            } else {
                return (
                    <Link style={{color: '#02709b'}} to={"/variant/GRCh38/" + site}>{ site }</Link>
                )
            }
        }
    }, {
        title: 'dbSNP',
        key: 'dbSNP',
        dataIndex: 'dbSNP',
        width: 150,
        render: (text) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }, {
        title: 'cDNA',
        key: 'cDNA',
        dataIndex: 'cDNA',
        width: 150,
        render: (text) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }, {
        title: 'Protein',
        key: 'Protein',
        dataIndex: 'protein',
        width: 200
    }, {
        title: 'Consequence',
        key: 'Consequence',
        dataIndex: 'Consequence',
        width: 200
    }, {
        title: 'CDGC',
        key: 'CDGC',
        dataIndex: 'CDGC_diagnosis',
        width: 150,
        render: (text) => (
            database(text)
        )
    }, {
        title: 'DVD',
        key: 'DVD',
        dataIndex: 'DVD_pathogenicity',
        width: 150,
        render: (text) => (
            database(text)
        )
    }, {
        title: 'ClinVar',
        key: 'ClinVar',
        dataIndex: 'Clinvar_CLNSIG',
        width: 150,
        render: (text) => (
            database(text)
        )
    }, {
        title: 'HGMD',
        key: 'HGMD',
        dataIndex: 'HGMD_Variantclass',
        width: 150,
        render: (text) => (
            database(text)
        )
    }, {
        title: 'DVPred',
        key: 'DVPred',
        dataIndex: 'DVPred',
        width: 150
    }, {
        title: 'Phenotype',
        key: 'Phenotype',
        dataIndex: 'Phenotype_dvd',
        width: 550
    },
    // {
    //     title: 'CDGC Control AF',
    //     key: 'CDGC Control AF',
    //     dataIndex: 'cdgc_cntrl_af',
    //     width: 250
    // },
    {
        title: 'gnomAD Max MAF',
        key: 'gnomAD Max MAF',
        dataIndex: 'gnomad',
        width: 250,
        render: (text) => {
            return (
                text['Allele Frequency']['popmax']
            )
        }
    }, {
        title: 'gnomAD Hom Count',
        key: 'gnomAD Hom Count',
        dataIndex: 'gnomad',
        width: 250,
        render: (text) => {
            return (
                text['Number of Homozygotes']['total']
            )
        }
    }, {
        title: 'PubMed',
        key: 'PubMed',
        width: 200,
        render: (record) => {
            let id = record.pub_dvd  + "|" + record.pub_hgmd;
            if (id === "|") {
                return ""
            } else {
                return (
                    <a href={"https://pubmed.ncbi.nlm.nih.gov/?term=" + id} target="_black"><Tag icon={<LinkOutlined />} color="#55acee">PubMed</Tag></a>
                )
            }
        }
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _omim = [
    {
        title: 'gene/locus MIM number',
        key: 'gene/locus MIM number',
        dataIndex: 'Gene_mim_number'
    }, {
        title: 'Phenotype',
        key: 'Phenotype',
        dataIndex: 'Phenotype'
    }, {
        title: 'Phenotype MIM number',
        key: 'Phenotype MIM number',
        dataIndex: 'Phenotype_mim_number'
    }, {
        title: 'Inheritance',
        key: 'Inheritance',
        dataIndex: 'Inheritance'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _hpo = [
    {
        title: 'HPO ID',
        key: 'HPO ID',
        dataIndex: 'Hpo_id',
        width: 200
    }, {
        title: 'Term Name',
        key: 'Term Name',
        dataIndex: 'Term_identifier',
        width: 300
    }, {
        title: 'Definition',
        key: 'Definition',
        dataIndex: 'Defination'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _mgi = [
    {
        title: 'Genotype',
        key: 'Genotype',
        dataIndex: 'Genotype',
        render: (text) => {
            try {
                let arr = text.split(',');
                let item = arr[0].split('/');
                let res = "";
                for (let i in item) {
                    let tmp = item[i].replace('>', '').split('<');
                    res += tmp[0] + "<sup>" + tmp[1] + "</sup>/"
                }
                let result = res + ',' + arr[1];
                return <span dangerouslySetInnerHTML={{ __html: result }}></span>
            } catch {
                return text
            }
        },
        width: 350
    }, {
        title: 'MGI ID',
        key: 'MGI ID',
        dataIndex: 'Mgi_id'
    }, {
        title: 'MP ID',
        key: 'MP ID',
        dataIndex: 'Mp_id'
    }, {
        title: 'MP terms',
        key: 'MP terms',
        dataIndex: 'Mp_term'
    }, {
        title: 'Phenotype definition',
        key: 'Phenotype definition',
        dataIndex: 'Phenotype_defination'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _go = [
    {
        title: 'GO ID',
        key: 'GO ID',
        dataIndex: 'Go_id'
    }, {
        title: 'Name',
        key: 'Name',
        dataIndex: 'Name'
    }, {
        title: 'Def',
        key: 'Def',
        dataIndex: 'Def'
    }, {
        title: 'Evidence',
        key: 'Evidence',
        dataIndex: 'Evidence'
    }, {
        title: 'Reference',
        key: 'Reference',
        dataIndex: 'Reference'
    }, {
        title: 'Date',
        key: 'Date',
        dataIndex: 'Date'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px'}})
}));

const _pubchem = [
    {
        title: 'Pathway',
        key: 'Pathway',
        dataIndex: 'Pathway'
    }, {
        title: 'Source',
        key: 'Source',
        dataIndex: 'Source'
    }, {
        title: 'External ID',
        key: 'External ID',
        dataIndex: 'External_ID',
        render: (text, record) => {
            if (record.Source === "Reactome") {
                return <a href={"https://reactome.org/PathwayBrowser/#/" + text} alt="Reactom url" className='target' target="_blank" rel="noopener noreferrer">{text}</a>
            } else {
                return text
            }
        }
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _compartment = [
    {
        title: 'Ensembl ID',
        key: 'Ensembl ID',
        dataIndex: 'Ensembl_id'
    }, {
        title: 'Name',
        key: 'Name',
        dataIndex: 'Name'
    }, {
        title: 'GO ID',
        key: 'GO ID',
        dataIndex: 'Go_id'
    }, {
        title: 'Confidence',
        key: 'Confidence',
        dataIndex: 'Confidence',
        render: (text) => {
            return (
                <Rate allowHalf disabled defaultValue={text} />
            )
        }
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _interpro = [
    {
        title: 'Uniprot ID',
        key: 'Uniprot ID',
        dataIndex: 'Uniprot_ID'
    }, {
        title: 'InterPro ID',
        key: 'InterPro ID',
        dataIndex: 'interPro_ID'
    }, {
        title: 'Name',
        key: 'Name',
        dataIndex: 'Short_name'
    }, {
        title: 'SMART ID',
        key: 'SMART ID',
        dataIndex: 'SMART_ID'
    }, {
        title: 'Start',
        key: 'Start',
        dataIndex: 'Domain_start'
    }, {
        title: 'End',
        key: 'End',
        dataIndex: 'Domain_end'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _biogrid = [
    {
        title: 'Source',
        key: 'Source',
        dataIndex: 'Source_Database',
        width: 150
    }, {
        title: 'ID',
        key: 'ID',
        dataIndex: 'BioGRID_Interaction_ID',
        width: 100
    }, {
        title: 'Interact with',
        key: 'Interact with',
        dataIndex: 'Official_Symbol_Interactor_B',
        width: 150
    }, {
        title: 'Qualifications',
        key: 'Qualifications',
        dataIndex: 'Qualifications'
    }, {
        title: 'Experimental System',
        key: 'Experimental System',
        dataIndex: 'Experimental_System',
        width: 250
    }, {
        title: 'Publication Source',
        key: 'Publication Source',
        dataIndex: 'Publication_Source',
        width: 250
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

const _dgidb = [
    {
        title: 'Drug Claim Primary Name',
        key: 'Drug Claim Primary Name',
        dataIndex: 'Drug_claim_primary_name'
    }, {
        title: 'Drug Chembl Id',
        key: 'Drug Chembl Id',
        dataIndex: 'Drug_chembl_id'
    }, {
        title: 'Interaction Type',
        key: 'Interaction Type',
        dataIndex: 'Interaction_types'
    }, {
        title: 'Source',
        key: 'Source',
        dataIndex: 'Source'
    }, {
        title: 'PMIDs',
        key: 'PMIDs',
        dataIndex: 'PMIDs'
    }
].map(c => Object.assign(c, {
    onCell: () => ({style: {height: '60px', padding: '0 16px'}}),
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));


export { _variant, _omim, _hpo, _mgi, _go, _pubchem, _compartment, _interpro, _biogrid, _dgidb };