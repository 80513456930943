import { Tag } from 'antd';

const ListItem = ({label, content, separate, color, background, delimiter=", "}) => {
    let tags = [];
    if (content === "") { content = "." }
    if (separate) {
        // let list = content.replace(";", "").split(delimiter)
        let list = content.split(delimiter)
        list.forEach((val, idx) => {
            tags.push(<Tag key={val+idx} style={{background: background, color: color, fontWeight: 'bold' }}>{val}</Tag>)
        })
    } else {
        if (content === ".") {
            tags.push(<Tag key={label}>.</Tag>)
        } else {
            tags.push(<Tag key={label} style={{background: background, color: color, fontWeight: background === "transparent" ? '' : 'bold' }}>{content}</Tag>)
        }
    }
    return (
        <div style={{width: '100%', marginBottom: '5px'}}>
            <span className='variant-thrid-title'>
                {label}: 
            </span>
            {tags}
        </div>
    )
};

export default ListItem