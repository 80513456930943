const mouseTableColumns = [
    {
        key: 'Mouse_symbol',
        dataIndex: 'Mouse_symbol',
        title: 'Mouse symbol',
        width: 200
    },
    {
        key: 'human_symbol',
        dataIndex: 'human_symbol',
        title: 'Human symbol',
        width: 200
    },
    {
        key: 'MP_ID',
        dataIndex: 'MP_ID',
        title: 'MP ID',
        width: 200
    },
    {
        key: 'MPTerm',
        dataIndex: 'MPTerm',
        title: 'MP Term',
        width: 200
    },
    {
        key: 'Phenotype_defination',
        dataIndex: 'Phenotype_defination',
        title: 'Phenotype defination',
    }
];

export { mouseTableColumns };