const columns = [
    {
        title: 'Variant',
        key: 'variant',
        render: (record) => {
            let res = "";
            if (JSON.stringify(record) !== "{}") {
                res = record.CHROM + ":" + record.POS + ":" + record.REF + ">" + record.ALT
            }
            return res 
        },
    }, {
        title: 'Prediction Score',
        key: 'ps',
        dataIndex: 'GBDT',
        render: (text) => {
            return text
        },
    }, {
        title: 'Prediction Result',
        key: 'pr',
        render: (record) => {
            let r = "", score = record.GBDT;
            if (score < 0.5) {
                r = "N"
            } else {
                r = "D"
            }
            return r
        },
    }, {
        title: 'SYMBOL',
        key: 'SYMBOL',
        dataIndex: 'SYMBOL',
    }, {
        title: 'HGVSc',
        key: 'HGVSc',
        dataIndex: 'HGVSc',
    }, {
        title: 'HGVSp',
        key: 'HGVSp',
        dataIndex: 'HGVSp',
    }, {
        title: 'Consequence',
        key: 'Consequence',
        dataIndex: 'Consequence',
    }, {
        title: 'IMPACT',
        key: 'IMPACT',
        dataIndex: 'Impact',
    }
].map(c => Object.assign(c, {
    onHeaderCell: () => ({style: {height: '60px', padding: '0 16px', background: '#3892b5', color: '#fff'}})
}));

export { columns }