import React, { Component } from 'react';
import { Row, Col, Table, message, Switch, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import asyncFetch from '../../utils/asyncFetch';
import { _biogrid } from './table_config';
import ReactEcharts from 'echarts-for-react';

const WithInteraction = (props) => {
    let params = useParams();
    return <Interaction {...props} params={params} />
}

const handleSystem = (gene, record) => {
    let system = [], children = []
    record.forEach((val, idx) => {
        system.push(val.Experimental_System)
    })
    system = [...new Set(system)]
    system.forEach((val, idx) => {
        let tmp = handleGene(val, record)
        children.push(tmp)
    })
    return {
        name: gene,
        size: children.length,
        children: children
    }
}

const handleGene = (key, record) => {
    let item = [], sub = []
    record.forEach((v, i) => {
        if (key === v.Experimental_System) {
            item.push(v.Official_Symbol_Interactor_B)
        }
    })
    const obj = item.reduce((obj, key) => {
        if (key in obj) {
            obj[key]++
        } else {
            obj[key] = 1
        }
        return obj
    }, {});
    for (const key in obj) {
        sub.push(
            {
                name: key,
                size: obj[key],
                value: obj[key]
            }
        )
    }
    return {
        name: key,
        size: sub.length,
        children: sub
    }
}

class Interaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gene: this.props.params.gene,
            record: [],
            data: {},
            checked: true,
            loading: true
        }
    }

    componentDidMount() {
        const { gene } = this.state;
        asyncFetch('GET', '/basic_table/biogrid_data', {SYMBOL: gene, current_page: 1, page_size: 10000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        record: result,
                        data: handleSystem(gene, result),
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    }
    
    onChange = (checked) => {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                checked,
                loading: false
            });
        }, 1000);
    }

    treemapOption(gene) {
        return {
            tooltip: {
                triggerOn: 'mousemove',
                formatter: function (info) {
                    var treePathInfo = info.treePathInfo
                    return treePathInfo[1].name + ': ' + gene + '-' + treePathInfo[2].name
                }
            },
            series: [
                {
                    type: 'treemap',
                    id: 'echarts-package-size',
                    animationDurationUpdate: 1000,
                    roam: false,
                    nodeClick: undefined,
                    data: this.state.data.children,
                    universalTransition: true,
                    label: {
                        show: true,
                    },
                    breadcrumb: {
                        show: false
                    }
                }
            ]
        };
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <Switch checkedChildren="Table" unCheckedChildren="Picture" defaultChecked onChange={this.onChange} style={{marginBottom: '20px'}} />
                        {
                            this.state.checked ? (
                                <Table
                                    scroll={{x: 1200}}
                                    columns={_biogrid}
                                    dataSource={this.state.record}
                                />
                            ) : (
                                <ReactEcharts option={this.treemapOption(this.state.gene)} style={{height: '750px'}} />
                            )
                        }
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default WithInteraction