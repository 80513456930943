import Layouts from '../pages/layouts';

import Home from '../pages/home';
import About from '../pages/about';
import Browse from '../pages/browse';
import Tool from '../pages/tool';
import Download from '../pages/download';
import Tutorial from '../pages/tutorial';
import Contact from '../pages/contact';
import Gene from '../pages/gene';
import Variant from '../pages/variant';

import BasicInfo from '../pages/gene/basicInfo';
import Density from '../pages/gene/density';
import VariantSite from '../pages/gene/variant';
import Pheontype from '../pages/gene/phenotype';
import Function from '../pages/gene/function';
import Pathway from '../pages/gene/pathway';
import Expression from '../pages/gene/expression';
import Subcellular from '../pages/gene/subcellular';
import Domain from '../pages/gene/domain';
import Interaction from '../pages/gene/interaction';
import Drug from '../pages/gene/drug';

const router = [
    {
        element: <Layouts />,
        children: [
            {
                path: '/',
                index: true,
                element: <Home />
            }, {
                path: '/about',
                element: <About />
            }, {
                path: '/browse',
                element: <Browse />
            }, {
                path: '/tool',
                element: <Tool />
            }, {
                path: '/download',
                element: <Download />
            }, {
                path: '/tutorial',
                element: <Tutorial />
            }, {
                path: '/contact',
                element: <Contact />
            }, {
                path: '/gene/:gene',
                element: <Gene />,
                children: [
                    {
                        index: true,
                        path: '/gene/:gene',
                        element: <BasicInfo />
                    }, {
                        path: '/gene/:gene/density',
                        element: <Density />
                    }, {
                        path: '/gene/:gene/variant',
                        element: <VariantSite />
                    }, {
                        path: '/gene/:gene/phenotype',
                        element: <Pheontype />
                    }, {
                        path: '/gene/:gene/function',
                        element: <Function />
                    }, {
                        path: '/gene/:gene/pathway',
                        element: <Pathway />
                    }, {
                        path: '/gene/:gene/expression',
                        element: <Expression />
                    }, {
                        path: '/gene/:gene/subcellular',
                        element: <Subcellular />
                    }, {
                        path: '/gene/:gene/domain',
                        element: <Domain />
                    }, {
                        path: '/gene/:gene/interaction',
                        element: <Interaction />
                    }, {
                        path: '/gene/:gene/drug',
                        element: <Drug />
                    }
                ]
            }, {
                path: '/variant/:version/:site',
                element: <Variant />
            }
        ]
    }
]

export default router