import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Button } from 'antd';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import filingsIcon from '../images/filings.png';

const { Header, Content, Footer } = Layout;

const Layouts = () => {
    let naviagte = useNavigate();
    let location = useLocation();
    const { t } = useTranslation();
    const [ current, setCurrent ] = useState(location.pathname);

    const onClick = e => {
        naviagte(e.key)
        setCurrent(e.key)
    }

    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname])

    const changeLang = (e) => {
        const lang = e.target.innerText;
        i18n.changeLanguage(lang);
    }

    const items=[
        {
            key: '/',
            label: t('menu_home'),
        }, {
            key: '/about',
            label: t('menu_about'),
        }, {
            key: '/browse',
            label: t('menu_browse'),
        }, {
            key: '/tool',
            label: t('menu_tool'),
        }, {
            key: '/download',
            label: t('menu_download'),
        }, {
            key: '/tutorial',
            label: t('menu_tutorial'),
        }, {
            key: '/contact',
            label: t('menu_contact'),
        }
    ]

    return (
        <Layout>
            <Header className={current === "/" ? '' : 'cover-layout-header'}>
                <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Col span={14}>
                        <span className='logo'>
                            <Link to="/">
                                {t('logo_title')}
                            </Link>
                        </span>
                        <span className="logo-description">
                            {t('logo_title_sub')}
                        </span>
                    </Col>
                    <Col span={10} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Menu
                            selectedKeys={[current]}
                            onClick={onClick}
                            mode='horizontal'
                            items={items}
                        />
                        <span className='lang'>
                            <Button type="text" onClick={(e) => changeLang(e)}>
                                { t('lang') }
                            </Button>
                        </span>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Outlet />
            </Content>
            <Footer>
                Web Development ©2022 Created by Institute of Rare Diseases, West China Hospital, Sichuan University
                <br />
                <img src={filingsIcon} style={{width: '14px', height: '14px', lineHeight: '14px', marginRight: '2px'}} alt='备案Icon' />
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" className="filings">川公网安备 51010702003484号</a>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" className="filings">蜀ICP备2023008958号-1</a>
            </Footer>
        </Layout>
    )
}

export default Layouts