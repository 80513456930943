import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './configs/locales/en.json'
import zhCN from './configs/locales/zh.json'

i18n
.use(initReactI18next)
.init({
    resources: {
        EN: {
            translation: enUS
        },
        CN: {
            translation: zhCN
        }
    },
    fallbackLng: "EN",
    debug: false,
    interpolation: {
        escapeValue: false
    }
})

export default i18n