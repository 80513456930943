const geoCoordMapZh = {
    "重庆市": [106.56288, 29.556742],
    "北京市": [116.418757, 39.917544],
    "乌鲁木齐市": [87.617733, 43.792818],
    "南宁市": [108.320004, 22.82402],
    "济南市": [117.000923, 36.675807],
    "兰州市": [103.823557, 36.058039],
    "石家庄市": [114.502461, 38.045474],
    "济宁市": [116.587245, 35.415393],
    "临沂市": [118.326443, 35.065282],
    "郑州市": [113.665412, 34.757975],
    "菏泽市": [115.469381, 35.246531],
    "长沙市": [112.982279, 28.19409],
    "银川市": [106.278179, 38.46637],
    "南昌市": [115.892151, 28.676493],
    "潍坊市": [119.107078, 36.70925],
    "徐州市": [117.184811, 34.261792],
    "西安市": [108.948024, 34.263161],
    "贵阳市": [106.713478, 26.578343],
    "昭通市": [103.717216, 27.336999],
    "上海市": [121.490317, 31.222771],
    "杭州市": [120.153576, 30.287459],
    "西宁市": [101.778916, 36.623178],
    "南充市": [106.082974, 30.795281],
    "哈尔滨市": [126.642464, 45.756967],
    "宿迁市": [118.275162, 33.963008],
    "成都市": [104.065735, 30.659462],
    "合肥市": [117.283042, 31.86119],
    "天津市": [117.195907, 39.118327],
    "黔东南苗族侗族自治州": [107.977488, 26.583352],
    "长春市": [125.3245, 43.886841],
    "武汉市": [114.298572, 30.584355],
    "南京市": [118.767413, 32.041544],
    "宜宾市": [104.630825, 28.760189],
    "玉林市": [110.154393, 22.63136],
    "邢台市": [114.508851, 37.0682],
    "邯郸市": [114.490686, 36.612273],
    "赣州市": [114.940278, 25.85097],
    "临夏回族自治州": [103.212006, 35.599446],
    "海口市": [110.33119, 20.031971],
    "钦州市": [108.624175, 21.967127],
    "乐山市": [103.761263, 29.582024],
    "宜昌市": [111.290843, 30.702636],
    "太原市": [112.549248, 37.857014],
    "沈阳市": [123.429096, 41.796767],
    "枣庄市": [117.557964, 34.856424],
    "梧州市": [111.297604, 23.474803],
    "呼和浩特市": [111.670801, 40.818311],
    "龙岩市": [117.02978, 25.091603],
    "绵阳市": [104.741722, 31.46402],
    "通辽市": [122.263119, 43.617429],
    "巴中市": [106.753669, 31.858809],
    "阜阳市": [115.819729, 32.896969],
    "聊城市": [115.980367, 36.456013],
    "福州市": [119.306239, 26.075302],
    "德阳市": [104.398651, 31.127991],
    "保山市": [99.167133, 25.111802],
    "德州市": [116.307428, 37.453968],
    "达州市": [107.502262, 31.209484],
    "广州市": [113.280637, 23.125178],
    "保定市": [115.482331, 38.867657],
    "宿州市": [116.984084, 33.633891],
    "贵港市": [109.602146, 23.0936],
    "南阳市": [112.540918, 32.999082],
    "泰安市": [117.129063, 36.194968],
    "连云港市": [119.178821, 34.600018],
    "遵义市": [106.937265, 27.706626],
    "昆明市": [102.712251, 25.040609],
    "荆门市": [112.204251, 31.03542],
    "四平市": [124.370785, 43.170344],
    "淄博市": [118.047648, 36.814939],
    "日喀则市": [88.885148, 29.267519],
    "淮北市": [116.794664, 33.971707],
    "黄石市": [115.077048, 30.220074],
    "商丘市": [115.650497, 34.437054],
    "荆州市": [112.23813, 30.326857],
    "拉萨市": [91.132212, 29.660361],
    "广安市": [106.633369, 30.456398],
    "吕梁市": [111.134335, 37.524366],
    "日照市": [119.461208, 35.428588],
    "贺州市": [111.552056, 24.414141],
    "自贡市": [104.773447, 29.352765],
    "桂林市": [110.299121, 25.274215],
    "绥化市": [126.99293, 46.637393],
    "来宾市": [109.229772, 23.733766],
    "周口市": [114.649653, 33.620357],
    "东营市": [118.66471, 37.434564],
    "开封市": [114.341447, 34.797049],
    "宜春市": [114.391136, 27.8043],
    "内江市": [105.066138, 29.58708],
    "喀什地区": [75.989138, 39.467664],
    "邵阳市": [111.46923, 27.237842],
    "遂宁市": [105.571331, 30.513311],
    "山南市": [91.766529, 29.236023],
    "岳阳市": [113.132855, 29.37029],
    "晋中市": [112.736465, 37.696495],
    "鄂尔多斯市": [109.99029, 39.817179],
    "包头市": [109.840405, 40.658168],
    "辽源市": [125.145349, 42.902692],
    "齐齐哈尔市": [123.95792, 47.342081],
    "乌兰察布市": [113.114543, 41.034126],
    "广元市": [105.829757, 32.433668],
    "资阳市": [104.641917, 30.122211],
    "衡阳市": [112.607693, 26.900358],
    "曲靖市": [103.797851, 25.501557],
    "衡水市": [115.665993, 37.735097],
    "信阳市": [114.075031, 32.123274],
    "襄阳市": [112.144146, 32.042426],
    "佛山市": [113.122717, 23.028762],
    "百色市": [106.616285, 23.897742],
    "黄冈市": [114.879365, 30.447711],
    "雅安市": [103.001033, 29.987722],
    "沧州市": [116.857461, 38.310582],
    "毕节市": [105.28501, 27.301693],
    "安阳市": [114.352482, 36.103442],
    "上饶市": [117.971185, 28.44442],
    "铜仁市": [109.191555, 27.718346],
    "抚州市": [116.358351, 27.98385],
    "湘潭市": [112.944052, 27.82973],
    "吉安市": [114.986373, 27.111699],
    "宁波市": [121.549792, 29.868388],
    "忻州市": [112.733538, 38.41769],
    "北海市": [109.119254, 21.473343],
    "驻马店市": [114.024736, 32.980169],
    "肇庆市": [112.472529, 23.051546],
    "许昌市": [113.826063, 34.022956],
    "楚雄彝族自治州": [101.546046, 25.041988],
    "常德市": [111.691347, 29.040225],
    "运城市": [111.003957, 35.022778],
    "揭阳市": [116.355733, 23.543778],
    "那曲市": [92.060214, 31.476004],
    "大同市": [113.295259, 40.09031],
    "长治市": [113.113556, 36.191112],
    "崇左市": [107.353926, 22.404108],
    "娄底市": [112.008497, 27.728136],
    "郴州市": [113.032067, 25.793589],
    "九江市": [115.992811, 29.712034],
    "昌都市": [97.178452, 31.136875],
    "台州市": [121.428599, 28.661378],
    "洛阳市": [112.434468, 34.663041],
    "滨州市": [118.016974, 37.383542],
    "临汾市": [111.517973, 36.08415],
    "柳州市": [109.411703, 24.314617],
    "河池市": [108.062105, 24.695899],
    "承德市": [117.939152, 40.976204],
    "晋城市": [112.851274, 35.497553],
    "金华市": [119.649506, 29.089524],
    "烟台市": [121.391382, 37.539297],
    "凉山彝族自治州": [102.258746, 27.886762],
    "儋州市": [109.576782, 19.517486],
    "汕尾市": [115.364238, 22.774485],
    "宁德市": [119.527082, 26.65924],
    "株洲市": [113.151737, 27.835806],
    "唐山市": [118.175393, 39.635113],
    "葫芦岛市": [120.856394, 40.755572],
    "怀化市": [109.97824, 27.550082],
    "汕头市": [116.708463, 23.37102],
    "永州市": [111.608019, 26.434516],
    "茂名市": [110.919229, 21.659751],
    "朝阳市": [120.451176, 41.576758],
    "衢州市": [118.87263, 28.941708],
    "红河哈尼族彝族自治州": [103.384182, 23.366775],
    "温州市": [120.672111, 28.000575],
    "鞍山市": [122.995632, 41.110626],
    "赤峰市": [118.956806, 42.275317],
    "新乡市": [113.883991, 35.302616],
    "黔西南布依族苗族自治州": [104.897971, 25.08812],
    "恩施土家族苗族自治州": [109.48699, 30.283114],
    "江门市": [113.094942, 22.590431],
    "青岛市": [120.355173, 36.082982],
    "锦州市": [121.135742, 41.119269],
    "澄迈县": [110.007147, 19.737095],
    "泸州市": [105.443348, 28.889138],
    "河源市": [114.697802, 23.746266],
    "黔南布依族苗族自治州": [107.517156, 26.258219],
    "平顶山市": [113.307718, 33.735241],
    "临高县": [109.687697, 19.908293],
    "益阳市": [112.355042, 28.570066],
    "濮阳市": [115.041299, 35.768234],
    "营口市": [122.235151, 40.667432],
    "萍乡市": [113.852186, 27.622946],
    "朔州市": [112.433387, 39.331261],
    "深圳市": [114.085947, 22.547],
    "铁岭市": [123.844279, 42.290585],
    "林芝市": [94.362348, 29.654693],
    "廊坊市": [116.704441, 39.523927],
    "云浮市": [112.044439, 22.929801],
    "东方市": [108.653789, 19.10198],
    "大连市": [121.618622, 38.91459],
    "张家口市": [114.884091, 40.811901],
    "普洱市": [100.972344, 22.777321],
    "文山壮族苗族自治州": [104.24401, 23.36951],
    "眉山市": [103.831788, 30.048318],
    "鹰潭市": [117.033838, 28.238638],
    "焦作市": [113.238266, 35.23904],
    "孝感市": [113.926655, 30.926423],
    "万宁市": [110.388793, 18.796216],
    "榆林市": [109.741193, 38.290162],
    "梅州市": [116.117582, 24.299112],
    "湛江市": [110.364977, 21.274898],
    "鄂州市": [114.890593, 30.396536],
    "清远市": [113.051227, 23.685022],
    "三门峡市": [111.194099, 34.777338],
    "巴彦淖尔市": [107.416959, 40.757402],
    "呼伦贝尔市": [119.758168, 49.215333],
    "六盘水市": [104.846743, 26.584643],
    "德宏傣族景颇族自治州": [98.578363, 24.436694],
    "牡丹江市": [129.618602, 44.582962],
    "防城港市": [108.345478, 21.614631],
    "张掖市": [100.455472, 38.932897],
    "漯河市": [114.026405, 33.575855],
    "丹东市": [124.383044, 40.124296],
    "惠州市": [114.412599, 23.079404],
    "韶关市": [113.591544, 24.801322],
    "丽水市": [119.921786, 28.451993],
    "白城市": [122.841114, 45.619026],
    "吉林市": [126.55302, 43.843577],
    "辽阳市": [123.18152, 41.269402],
    "抚顺市": [123.921109, 41.875956],
    "通化市": [125.936501, 41.721177],
    "屯昌县": [110.102773, 19.362916],
    "琼海市": [110.466785, 19.246011],
    "陵水黎族自治县": [110.037218, 18.505006],
    "大庆市": [125.11272, 46.590734],
    "本溪市": [123.770519, 41.297909],
    "东莞市": [113.746262, 23.046237],
    "鸡西市": [130.975966, 45.300046],
    "绍兴市": [120.582112, 29.997117],
    "随州市": [113.37377, 31.717497],
    "安庆市": [117.043551, 30.50883],
    "湖州市": [120.102398, 30.867198],
    "莆田市": [119.007558, 25.431011],
    "渭南市": [109.502882, 34.499381],
    "潮州市": [116.632301, 23.661701],
    "阿坝藏族羌族自治州": [102.221374, 31.899792],
    "阜新市": [121.648962, 42.011796],
    "咸宁市": [114.328963, 29.832798],
    "阿里地区": [80.105498, 32.503187],
    "丽江市": [100.233026, 26.872108],
    "安顺市": [105.932188, 26.245544],
    "盘锦市": [122.06957, 41.124484],
    "阳泉市": [113.583285, 37.861188],
    "南通市": [120.864608, 32.016212],
    "潜江市": [112.896866, 30.421215],
    "新余市": [114.930835, 27.810834],
    "亳州市": [115.782939, 33.869338],
    "扬州市": [119.421003, 32.393159],
    "南平市": [118.178459, 26.635627],
    "佳木斯市": [130.361634, 46.809606],
    "泉州市": [118.589421, 24.908853],
    "中山市": [113.382391, 22.521113],
    "天门市": [113.165862, 30.653061],
    "临沧市": [100.08697, 23.886567],
    "七台河市": [131.015584, 45.771266],
    "湘西土家族苗族自治州": [109.739735, 28.314296],
    "兴安盟": [122.070317, 46.076268],
    "嘉兴市": [120.750865, 30.762653],
    "阳江市": [111.975107, 21.859222],
    "大兴安岭地区": [124.711526, 52.335262],
    "定安县": [110.349235, 19.684966],
    "三明市": [117.635001, 26.265444],
    "双鸭山市": [131.157304, 46.643442],
    "乐东黎族自治县": [109.175444, 18.74758],
    "伊春市": [128.899396, 47.724775],
    "秦皇岛市": [119.586579, 39.942531],
    "白山市": [126.427839, 41.942505],
    "珠海市": [113.553986, 22.224979],
    "鹤壁市": [114.295444, 35.748236],
    "怒江傈僳族自治州": [98.854304, 25.850949],
    "西双版纳傣族自治州": [100.797941, 22.001724],
    "三亚市": [109.508268, 18.247872],
    "庆阳市": [107.638372, 35.734218],
    "舟山市": [122.106863, 30.016028],
    "甘孜藏族自治州": [101.963815, 30.050663],
    "攀枝花市": [101.716007, 26.580446],
    "无锡市": [120.301663, 31.574729],
    "锡林郭勒盟": [116.090996, 43.944018],
    "宝鸡市": [107.14487, 34.369315],
    "盐城市": [120.139998, 33.377631],
    "延边朝鲜族自治州": [129.513228, 42.904823],
    "昌江黎族自治县": [109.053351, 19.260968],
    "景德镇市": [117.214664, 29.29256],
    "文昌市": [110.753975, 19.612986],
    "威海市": [122.116394, 37.509691],
    "神农架林区": [110.671525, 31.744449],
    "蚌埠市": [117.363228, 32.939667],
    "泰州市": [119.915176, 32.484882],
    "大理白族自治州": [100.225668, 25.589449],
    "淮安市": [119.021265, 33.597506],
    "仙桃市": [113.453974, 30.364953],
    "延安市": [109.49081, 36.596537],
    "陇南市": [104.929379, 33.388598],
    "海西蒙古族藏族自治州": [97.370785, 37.374663],
    "苏州市": [120.619585, 31.299379],
    "鹤岗市": [130.277487, 47.332085],
    "定西市": [104.626294, 35.579578],
    "松原市": [124.823608, 45.118243],
    "镇江市": [119.452753, 32.204402],
    "常州市": [119.946973, 31.772752],
    "乌海市": [106.825563, 39.673734],
    "武威市": [102.634697, 37.929996],
    "汉中市": [107.028621, 33.077668],
    "香港": [114.1829153, 22.27638889],
    "克孜勒苏柯尔克孜自治州": [76.172825, 39.713431],
    "漳州市": [117.661801, 24.510897],
    "五指山市": [109.516662, 18.776921],
    "芜湖市": [118.376451, 31.326319],
    "石嘴山市": [106.376173, 39.01333],
    "滁州市": [118.316264, 32.303627],
    "六安市": [116.507676, 31.752889],
    "酒泉市": [98.510795, 39.744023],
    "固原市": [106.285241, 36.004561],
    "铜陵市": [117.816576, 30.929935],
    "黑河市": [127.499023, 50.249585],
    "吴忠市": [106.199409, 37.986165],
    "平凉市": [106.684691, 35.54279],
    "阿拉善盟": [105.706422, 38.844814],
    "淮南市": [117.018329, 32.647574],
    "张家界市": [110.479921, 29.127401],
    "白沙黎族自治县": [109.452606, 19.224584],
    "石河子市": [86.041075, 44.305886]
}

const geoCoordMapEn = {
    "Chongqing": [106.56288, 29.556742],
    "Beijing": [116.418757, 39.917544],
    "Urumqi": [87.617733, 43.792818],
    "Nanning": [108.320004, 22.82402],
    "Jinan": [117.000923, 36.675807],
    "Lanzhou": [103.823557, 36.058039],
    "Shijiazhuang": [114.502461, 38.045474],
    "Jining": [116.587245, 35.415393],
    "Linyi": [118.326443, 35.065282],
    "Zhengzhou": [113.665412, 34.757975],
    "Heze": [115.469381, 35.246531],
    "Changsha": [112.982279, 28.19409],
    "Yinchuan": [106.278179, 38.46637],
    "Nanchang": [115.892151, 28.676493],
    "WEIFANG": [119.107078, 36.70925],
    "Xuzhou": [117.184811, 34.261792],
    "Xi'an": [108.948024, 34.263161],
    "Guiyang": [106.713478, 26.578343],
    "Zhaotong": [103.717216, 27.336999],
    "Shanghai": [121.490317, 31.222771],
    "Hangzhou": [120.153576, 30.287459],
    "Xining": [101.778916, 36.623178],
    "Nanchong": [106.082974, 30.795281],
    "Harbin": [126.642464, 45.756967],
    "Suqian": [118.275162, 33.963008],
    "Chengdu": [104.065735, 30.659462],
    "Hefei": [117.283042, 31.86119],
    "Tianjin": [117.195907, 39.118327],
    "Qiandongnan": [107.977488, 26.583352],
    "Changchun": [125.3245, 43.886841],
    "Wuhan": [114.298572, 30.584355],
    "Nanjing": [118.767413, 32.041544],
    "Yibin": [104.630825, 28.760189],
    "Yu'lin": [110.154393, 22.63136],
    "Xingtai": [114.508851, 37.0682],
    "Handan": [114.490686, 36.612273],
    "Ganzhou": [114.940278, 25.85097],
    "Linxia": [103.212006, 35.599446],
    "Haikou": [110.33119, 20.031971],
    "Qinzhou": [108.624175, 21.967127],
    "Leshan": [103.761263, 29.582024],
    "Yichang": [111.290843, 30.702636],
    "Taiyuan": [112.549248, 37.857014],
    "Shenyang": [123.429096, 41.796767],
    "Zaozhuang": [117.557964, 34.856424],
    "Wuzhou": [111.297604, 23.474803],
    "Hohhot": [111.670801, 40.818311],
    "Longyan": [117.02978, 25.091603],
    "Mianyang": [104.741722, 31.46402],
    "Tongliao": [122.263119, 43.617429],
    "Bazhong": [106.753669, 31.858809],
    "Fuyang": [115.819729, 32.896969],
    "Liaocheng": [115.980367, 36.456013],
    "Fu'zhou": [119.306239, 26.075302],
    "Deyang": [104.398651, 31.127991],
    "Baoshan": [99.167133, 25.111802],
    "Dezhou": [116.307428, 37.453968],
    "Dazhou": [107.502262, 31.209484],
    "Guangzhou": [113.280637, 23.125178],
    "Baoding": [115.482331, 38.867657],
    "Su'zhou": [116.984084, 33.633891],
    "Guigang": [109.602146, 23.0936],
    "Nanyang": [112.540918, 32.999082],
    "Tai'an": [117.129063, 36.194968],
    "Lianyungang": [119.178821, 34.600018],
    "Zunyi": [106.937265, 27.706626],
    "Kunming": [102.712251, 25.040609],
    "Jingmen": [112.204251, 31.03542],
    "Siping": [124.370785, 43.170344],
    "Zibo": [118.047648, 36.814939],
    "Shigatse": [88.885148, 29.267519],
    "Huaibei": [116.794664, 33.971707],
    "Huangshi": [115.077048, 30.220074],
    "Shangqiu": [115.650497, 34.437054],
    "Jingzhou": [112.23813, 30.326857],
    "Lhasa": [91.132212, 29.660361],
    "Guang'an": [106.633369, 30.456398],
    "Lvliang": [111.134335, 37.524366],
    "Rizhao": [119.461208, 35.428588],
    "Hezhou": [111.552056, 24.414141],
    "Zigong": [104.773447, 29.352765],
    "Guilin": [110.299121, 25.274215],
    "Suihua": [126.99293, 46.637393],
    "Laibin": [109.229772, 23.733766],
    "Zhoukou": [114.649653, 33.620357],
    "Dongying": [118.66471, 37.434564],
    "Kaifeng": [114.341447, 34.797049],
    "Yichun": [114.391136, 27.8043],
    "Neijiang": [105.066138, 29.58708],
    "Kashgar": [75.989138, 39.467664],
    "Shaoyang": [111.46923, 27.237842],
    "Suining": [105.571331, 30.513311],
    "Shannan": [91.766529, 29.236023],
    "Yueyang": [113.132855, 29.37029],
    "Jinzhong": [112.736465, 37.696495],
    "Ordos": [109.99029, 39.817179],
    "Baotou": [109.840405, 40.658168],
    "Liaoyuan": [125.145349, 42.902692],
    "Qiqihar": [123.95792, 47.342081],
    "Ulanqab": [113.114543, 41.034126],
    "Guangyuan": [105.829757, 32.433668],
    "Ziyang": [104.641917, 30.122211],
    "Hengyang": [112.607693, 26.900358],
    "Qujing": [103.797851, 25.501557],
    "Hengshui": [115.665993, 37.735097],
    "Xinyang": [114.075031, 32.123274],
    "Xiangyang": [112.144146, 32.042426],
    "Foshan": [113.122717, 23.028762],
    "Baise": [106.616285, 23.897742],
    "Huanggang": [114.879365, 30.447711],
    "Ya'an": [103.001033, 29.987722],
    "Cangzhou": [116.857461, 38.310582],
    "Bijie": [105.28501, 27.301693],
    "Anyang": [114.352482, 36.103442],
    "Shangrao": [117.971185, 28.44442],
    "Tongren": [109.191555, 27.718346],
    "Fuzhou": [116.358351, 27.98385],
    "Xiangtan": [112.944052, 27.82973],
    "Ji'an": [114.986373, 27.111699],
    "Ningbo": [121.549792, 29.868388],
    "Xinzhou": [112.733538, 38.41769],
    "Beihai": [109.119254, 21.473343],
    "Zhumadian": [114.024736, 32.980169],
    "Zhaoqing": [112.472529, 23.051546],
    "Xuchang": [113.826063, 34.022956],
    "Chuxiong": [101.546046, 25.041988],
    "Changde": [111.691347, 29.040225],
    "Yuncheng": [111.003957, 35.022778],
    "Jieyang": [116.355733, 23.543778],
    "Nagqu": [92.060214, 31.476004],
    "Datong": [113.295259, 40.09031],
    "Changzhi": [113.113556, 36.191112],
    "Chongzuo": [107.353926, 22.404108],
    "Loudi": [112.008497, 27.728136],
    "Chenzhou": [113.032067, 25.793589],
    "Jiujiang": [115.992811, 29.712034],
    "Changdu": [97.178452, 31.136875],
    "Taizhou": [121.428599, 28.661378],
    "Luoyang": [112.434468, 34.663041],
    "Binzhou": [118.016974, 37.383542],
    "Linfen": [111.517973, 36.08415],
    "Liuzhou": [109.411703, 24.314617],
    "Hechi": [108.062105, 24.695899],
    "Chengde": [117.939152, 40.976204],
    "Jincheng": [112.851274, 35.497553],
    "Jinhua": [119.649506, 29.089524],
    "Yantai": [121.391382, 37.539297],
    "Liangshan": [102.258746, 27.886762],
    "Danzhou": [109.576782, 19.517486],
    "Shanwei": [115.364238, 22.774485],
    "Ningde": [119.527082, 26.65924],
    "Zhuzhou": [113.151737, 27.835806],
    "Tangshan": [118.175393, 39.635113],
    "Huludao": [120.856394, 40.755572],
    "Huaihua": [109.97824, 27.550082],
    "Shantou": [116.708463, 23.37102],
    "Yongzhou": [111.608019, 26.434516],
    "Maoming": [110.919229, 21.659751],
    "Chaoyang": [120.451176, 41.576758],
    "Quzhou": [118.87263, 28.941708],
    "Honghe": [103.384182, 23.366775],
    "Wenzhou": [120.672111, 28.000575],
    "Anshan": [122.995632, 41.110626],
    "Chifeng": [118.956806, 42.275317],
    "Xinxiang": [113.883991, 35.302616],
    "Qianxinan": [104.897971, 25.08812],
    "Enshi": [109.48699, 30.283114],
    "Jiangmen": [113.094942, 22.590431],
    "Qingdao": [120.355173, 36.082982],
    "Jinzhou": [121.135742, 41.119269],
    "Chengmai": [110.007147, 19.737095],
    "Luzhou": [105.443348, 28.889138],
    "Heyuan": [114.697802, 23.746266],
    "Qiannan": [107.517156, 26.258219],
    "Pingdingshan": [113.307718, 33.735241],
    "Lingao": [109.687697, 19.908293],
    "Yiyang": [112.355042, 28.570066],
    "Puyang": [115.041299, 35.768234],
    "Yingkou": [122.235151, 40.667432],
    "Pingxiang": [113.852186, 27.622946],
    "Shuozhou": [112.433387, 39.331261],
    "Shenzhen": [114.085947, 22.547],
    "Tieling": [123.844279, 42.290585],
    "Nyingchi": [94.362348, 29.654693],
    "Langfang": [116.704441, 39.523927],
    "Yunfu": [112.044439, 22.929801],
    "Dongfang": [108.653789, 19.10198],
    "Dalian": [121.618622, 38.91459],
    "Zhangjiakou": [114.884091, 40.811901],
    "Pu'er": [100.972344, 22.777321],
    "Wenshan": [104.24401, 23.36951],
    "Meishan": [103.831788, 30.048318],
    "Yingtan": [117.033838, 28.238638],
    "Jiaozuo": [113.238266, 35.23904],
    "Xiaogan": [113.926655, 30.926423],
    "Wanning": [110.388793, 18.796216],
    "Yulin": [109.741193, 38.290162],
    "Meizhou": [116.117582, 24.299112],
    "Zhanjiang": [110.364977, 21.274898],
    "Ezhou": [114.890593, 30.396536],
    "Qingyuan": [113.051227, 23.685022],
    "Sanmenxia": [111.194099, 34.777338],
    "Bayan Nur": [107.416959, 40.757402],
    "Hulunbuir": [119.758168, 49.215333],
    "Liupanshui": [104.846743, 26.584643],
    "Dehong": [98.578363, 24.436694],
    "Mudanjiang": [129.618602, 44.582962],
    "Fangchenggang": [108.345478, 21.614631],
    "Zhangye": [100.455472, 38.932897],
    "Luohe": [114.026405, 33.575855],
    "Dandong": [124.383044, 40.124296],
    "Huizhou": [114.412599, 23.079404],
    "Shaoguan": [113.591544, 24.801322],
    "Lishui": [119.921786, 28.451993],
    "Baicheng": [122.841114, 45.619026],
    "Jilin": [126.55302, 43.843577],
    "Liaoyang": [123.18152, 41.269402],
    "Fushun": [123.921109, 41.875956],
    "Tonghua": [125.936501, 41.721177],
    "Tunchang": [110.102773, 19.362916],
    "Qionghai": [110.466785, 19.246011],
    "Lingshui": [110.037218, 18.505006],
    "Daqing": [125.11272, 46.590734],
    "Benxi": [123.770519, 41.297909],
    "Dongguan": [113.746262, 23.046237],
    "Jixi": [130.975966, 45.300046],
    "Shaoxing": [120.582112, 29.997117],
    "Suizhou": [113.37377, 31.717497],
    "Anqing": [117.043551, 30.50883],
    "Huzhou": [120.102398, 30.867198],
    "Putian": [119.007558, 25.431011],
    "Weinan": [109.502882, 34.499381],
    "Chaozhou": [116.632301, 23.661701],
    "Aba": [102.221374, 31.899792],
    "Fuxin": [121.648962, 42.011796],
    "Xianning": [114.328963, 29.832798],
    "Ali": [80.105498, 32.503187],
    "Lijiang": [100.233026, 26.872108],
    "Anshun": [105.932188, 26.245544],
    "Panjin": [122.06957, 41.124484],
    "Yangquan": [113.583285, 37.861188],
    "Nantong": [120.864608, 32.016212],
    "Qianjiang": [112.896866, 30.421215],
    "Xinyu": [114.930835, 27.810834],
    "Bozhou": [115.782939, 33.869338],
    "Yangzhou": [119.421003, 32.393159],
    "Nanping": [118.178459, 26.635627],
    "Jiamusi": [130.361634, 46.809606],
    "Quanzhou": [118.589421, 24.908853],
    "Zhongshan": [113.382391, 22.521113],
    "Tianmen": [113.165862, 30.653061],
    "Lincang": [100.08697, 23.886567],
    "Qitaihe": [131.015584, 45.771266],
    "Xiangxi": [109.739735, 28.314296],
    "Xing'an League": [122.070317, 46.076268],
    "Jiaxing": [120.750865, 30.762653],
    "Yangjiang": [111.975107, 21.859222],
    "Daxing'anling": [124.711526, 52.335262],
    "Ding'an": [110.349235, 19.684966],
    "Sanming": [117.635001, 26.265444],
    "Shuangyashan": [131.157304, 46.643442],
    "Ledong": [109.175444, 18.74758],
    "Yi'chun": [128.899396, 47.724775],
    "Qinhuangdao": [119.586579, 39.942531],
    "Baishan": [126.427839, 41.942505],
    "Zhuhai": [113.553986, 22.224979],
    "Hebi": [114.295444, 35.748236],
    "Nujiang": [98.854304, 25.850949],
    "Xishuangbanna": [100.797941, 22.001724],
    "Sanya": [109.508268, 18.247872],
    "Qingyang": [107.638372, 35.734218],
    "Zhoushan": [122.106863, 30.016028],
    "Garze": [101.963815, 30.050663],
    "Panzhihua": [101.716007, 26.580446],
    "Wuxi": [120.301663, 31.574729],
    "Xilin Gol League": [116.090996, 43.944018],
    "Baoji": [107.14487, 34.369315],
    "Yancheng": [120.139998, 33.377631],
    "Yanbian": [129.513228, 42.904823],
    "Changjiang": [109.053351, 19.260968],
    "Jingdezhen": [117.214664, 29.29256],
    "Wenchang": [110.753975, 19.612986],
    "Weihai": [122.116394, 37.509691],
    "Shennongjia": [110.671525, 31.744449],
    "Bengbu": [117.363228, 32.939667],
    "Tai'zhou": [119.915176, 32.484882],
    "Dali": [100.225668, 25.589449],
    "Huaian": [119.021265, 33.597506],
    "Xiantao": [113.453974, 30.364953],
    "Yan'an": [109.49081, 36.596537],
    "Longnan": [104.929379, 33.388598],
    "Haixi": [97.370785, 37.374663],
    "Suzhou": [120.619585, 31.299379],
    "Hegang": [130.277487, 47.332085],
    "Dingxi": [104.626294, 35.579578],
    "Songyuan": [124.823608, 45.118243],
    "Zhenjiang": [119.452753, 32.204402],
    "Changzhou": [119.946973, 31.772752],
    "Wuhai": [106.825563, 39.673734],
    "Wuwei": [102.634697, 37.929996],
    "Hanzhou": [107.028621, 33.077668],
    "Hong Kong": [114.1829153, 22.27638889],
    "Kizilsu": [76.172825, 39.713431],
    "Zhangzhou": [117.661801, 24.510897],
    "Wuzhishan": [109.516662, 18.776921],
    "Wuhu": [118.376451, 31.326319],
    "Shizuishan": [106.376173, 39.01333],
    "Chuzhou": [118.316264, 32.303627],
    "Lu'an": [116.507676, 31.752889],
    "Jiuquan": [98.510795, 39.744023],
    "Guyuan": [106.285241, 36.004561],
    "Tongling": [117.816576, 30.929935],
    "Heihe": [127.499023, 50.249585],
    "Wuzhong": [106.199409, 37.986165],
    "Pingliang": [106.684691, 35.54279],
    "Alxa League": [105.706422, 38.844814],
    "Huainan": [117.018329, 32.647574],
    "Zhangjiajie": [110.479921, 29.127401],
    "Baisha": [109.452606, 19.224584],
    "Shihezi": [86.041075, 44.305886]
}

export { geoCoordMapZh, geoCoordMapEn }