const database_columns = [
    {
        title: 'CATAGORY',
        key: 'Catagory',
        dataIndex: 'Catagory',
        width: 200
    },
    {
        title: 'DATABASE',
        key: 'Database',
        dataIndex: 'Database',
        width: 200
    },
    {
        title: 'DESCRIPTION',
        key: 'Description',
        dataIndex: 'Description',
    },
    {
        title: 'URL',
        key: 'URL',
        dataIndex: 'URL',
        render: text => {
            return (
                <a href={text} targett="_blank" style={{color: '#1890ff'}}>{text}</a>
            )
        }
    }
];

const database_source = [
    {
        "key": "1",
        "Catagory": "Gene Information",
        "Database": "Entrez",
        "Description": "The Entrez Global Query Cross-Database Search System is a federated search engine, or web portal that allows users to search many discrete health sciences databases at the National Center for Biotechnology Information (NCBI) website.[2] The NCBI is a part of the National Library of Medicine (NLM), which is itself a department of the National Institutes of Health (NIH), which in turn is a part of the United States Department of Health and Human Services. The name 'Entrez' (a greeting meaning 'Come in' in French) was chosen to reflect the spirit of welcoming the public to search the content available from the NLM.",
        "URL": "https://www.ncbi.nlm.nih.gov/gene"
    },
    {
        "key": "2",
        "Catagory": "Gene Information",
        "Database": "NCBI Gene",
        "Description": "Gene has been implemented at NCBI to characterize and organize the information about genes. It serves as a major node in the nexus of the genomic map, expression, sequence, protein function, structure, and homology data.",
        "URL": "https://www.ncbi.nlm.nih.gov/gene/"
    },
    {
        "key": "3",
        "Catagory": "Gene Information",
        "Database": "HGNC",
        "Description": "The HUGO Gene Nomenclature Committee (HGNC) is a committee of the Human Genome Organisation (HUGO) that sets the standards for human gene nomenclature. HGNC is responsible for approving unique symbols and names for human loci, including protein coding genes, ncRNA genes and pseudogenes, to allow unambiguous scientific communication.",
        "URL": "http://www.genenames.org"
    },
    {
        "key": "4",
        "Catagory": "Gene Information",
        "Database": "Ensembl",
        "Description": "Ensembl is a genome browser for vertebrate genomes that supports research in comparative genomics, evolution, sequence variation and transcriptional regulation. Ensembl annotate genes, computes multiple alignments, predicts regulatory function and collects disease data. Ensembl tools include BLAST, BLAT, BioMart and the Variant Effect Predictor (VEP) for all supported species.",
        "URL": "http://asia.ensembl.org"
    },
    {
        "key": "5",
        "Catagory": "Gene Information",
        "Database": "GeneCards",
        "Description": "GeneCards is a searchable, integrative database that provides comprehensive, user-friendly information on all annotated and predicted human genes. The knowledgebase automatically integrates gene-centric data from ~150 web sources, including genomic, transcriptomic, proteomic, genetic, clinical and functional information.",
        "URL": "http://www.genecards.org/"
    },
    {
        "key": "6",
        "Catagory": "Subcellular Location",
        "Database": "COMPARTMENTS",
        "Description": "COMPARTMENTS is a weekly updated web resource that integrates evidence on protein subcellular localization from manually curated literature, high-throughput screens, automatic text mining, and sequence-based prediction methods.",
        "URL": "https://compartments.jensenlab.org/"
    },
    {
        "key": "7",
        "Catagory": "Disease & Phenotype",
        "Database": "OMIM",
        "Description": "OMIM (online mendelian inheritance in man) is a comprehensive, authoritative compendium of human genes and genetic phenotypes that is freely available and updated daily. The full-text, referenced overviews in OMIM contain information on all known mendelian disorders and over 16,000 genes. And it focuses on the relationship between phenotype and genotype.",
        "URL": "https://omim.org"
    },
    {
        "key": "8",
        "Catagory": "Disease & Phenotype",
        "Database": "MGI",
        "Description": "Mouse Genome Informatics (MGI)  is the international database resource for the laboratory mouse, providing integrated genetic, genomic, and biological data to facilitate the study of human health and disease.",
        "URL": "http://www.informatics.jax.org"
    },
    {
        "key": "9",
        "Catagory": "Disease & Phenotype",
        "Database": "HPO",
        "Description": "The Human Phenotype Ontology (HPO) provides a standardized vocabulary of phenotypic abnormalities encountered in human disease. Each term in the HPO describes a phenotypic abnormality, such as Atrial septal defect. The HPO is currently being developed using the medical literature, Orphanet, DECIPHER, and OMIM. HPO currently contains over 13,000 terms and over 156,000 annotations to hereditary diseases. The ontology contains over 50,000 annotations between phenotypes and hereditary disease.",
        "URL": "http://human-phenotype-ontology.github.io/"
    },
    {
        "key": "10",
        "Catagory": "Disease & Phenotype",
        "Database": "Genetics home reference",
        "Description": "Genetics Home Reference provides  consumer-friendly information about the effects of genetic variation on human health.",
        "URL": "https://ghr.nlm.nih.gov"
    },
    {
        "key": "11",
        "Catagory": "Disease & Phenotype",
        "Database": "ClinGen",
        "Description": "ClinGen is a National Institutes of Health (NIH)-funded resource dedicated to building a central resource that defines the clinical relevance of genes and variants for use in precision medicine and research",
        "URL": "https://www.clinicalgenome.org"
    },
    {
        "key": "12",
        "Catagory": "Protein Domain",
        "Database": "InterPro",
        "Description": "InterPro is a resource that provides functional analysis of protein sequences by classifying them into families and predicting the presence of domains and important sites. To classify proteins in this way, InterPro uses predictive models, known as signatures, provided by several different databases (referred to as member databases) that make up the InterPro consortium.It combines signatures from multiple, diverse databases into a single searchable resource, reducing redundancy and helping users interpret their sequence analysis results.",
        "URL": "http://www.ebi.ac.uk/interpro/"
    },
    {
        "key": "13",
        "Catagory": "Gene Function",
        "Database": "UniProtKB",
        "Description": "The UniProt Knowledgebase (UniProtKB) is the central hub for the collection of functional information on proteins, with accurate, consistent and rich annotation. In addition to capturing the core data mandatory for each UniProtKB entry (mainly, the amino acid sequence, protein name or description, taxonomic data and citation information), as much annotation information as possible is added.",
        "URL": "http://www.uniprot.org/uniprot/"
    },
    {
        "key": "14",
        "Catagory": "Gene Function",
        "Database": "Gene Ontology",
        "Description": "The Gene Ontology (GO) knowledgebase provides a computational representation of our current scientific knowledge about the functions of genes (or, more properly, the protein and non-coding RNA molecules produced by genes) from many different organisms, from humans to bacteria. It is widely used to support scientific research, and has been cited in tens of thousands of publications. ",
        "URL": "http://geneontology.org"
    },
    {
        "key": "15",
        "Catagory": "Gene Pathway",
        "Database": "Reactome",
        "Description": "REACTOME is an open-source, open access, manually curated and peer-reviewed pathway database. Our goal is to provide intuitive bioinformatics tools for the visualization, interpretation and analysis of pathway knowledge to support basic and clinical research, genome analysis, modeling, systems biology and education",
        "URL": "https://reactome.org/"
    },
    {
        "key": "16",
        "Catagory": "Gene Pathway",
        "Database": "PubChem",
        "Description": "PubChem is an open chemistry database at the National Institutes of Health (NIH). 'Open' means that you can put your scientific data in PubChem and that others may use it. Since the launch in 2004, PubChem has become a key chemical information resource for scientists, students, and the general public. Each month our website and programmatic services provide data to several million users worldwide.",
        "URL": "https://pubchem.ncbi.nlm.nih.gov/"
    },
    {
        "key": "17",
        "Catagory": "Gene Expression",
        "Database": "GTEx",
        "Description": "The Genotype-Tissue Expression (GTEx) project is an ongoing effort to build a comprehensive public resource to study tissue-specific gene expression and regulation. Samples were collected from 54 non-diseased tissue sites across nearly 1000 individuals, primarily for molecular assays including WGS, WES, and RNA-Seq. Remaining samples are available from the GTEx Biobank. The GTEx Portal provides open access to data including gene expression, QTLs, and histology images.",
        "URL": "http://www.gtexportal.org/"
    },
    {
        "key": "18",
        "Catagory": "Gene Expression",
        "Database": "gEAR",
        "Description": "The gEAR portal provides a single platform for data deposition, display, analysis and interrogation, with a focus on allowing users to customize the site and their own data displays.The gEAR portal\u2019s specialized workflow for single-cell RNA-seq analysis enables a user to upload their dataset and use the Analysis Workbench to perform a stepwise analysis, including quality control, principal component analysis, clustering, dimensionality reduction and marker gene identification.",
        "URL": "https://umgear.org/"
    },
    {
        "key": "19",
        "Catagory": "Protein-Protein Interaction",
        "Database": "BioGrid",
        "Description": "BioGRID is a biomedical interaction repository with data compiled through comprehensive curation efforts. Our current index is version 4.4.229 and searches 83,957 publications for 2,711,919 protein and genetic interactions, 31,144 chemical interactions and 1,128,339 post translational modifications from major model organism species.",
        "URL": "https://thebiogrid.org/109986/summary/homo-sapiens"
    },
    {
        "key": "20",
        "Catagory": "Protein-Protein Interaction",
        "Database": "STRING",
        "Description": "The STRING database is a protein-protein interaction network database based on public databases and literature information. It collects data from various public databases, including UniProt,Pfam, KEGG and Gene Ontology, integrates this information, and generates a comprehensive protein-protein interaction network database.",
        "URL": "https://www.string-db.org/"
    },
    {
        "key": "21",
        "Catagory": "Gene-Drug",
        "Database": "DGIdb",
        "Description": "The Drug-Gene Interaction database (DGIdb) mines existing resources that generate hypotheses about how mutated genes might be targeted therapeutically or prioritized for drug development. It provides an interface for searching lists of genes against a compendium of drug-gene interactions and potentially 'druggable' genes.Currently, DGIdb contains over 40,000 genes and 10,000 drugs involved in over 100,000 drug-gene interactions or belonging to one of 42 potentially druggable gene categories.",
        "URL": "http://dgidb.genome.wustl.edu/"
    },
    {
        "key": "22",
        "Catagory": "Gene-Drug",
        "Database": "PharmGKB",
        "Description": "The Pharmacogenomics Knowledgebase (PharmGKB) is a publicly available, online knowledge base responsible for the aggregation, curation, integration and dissemination of knowledge regarding the impact of human genetic variation on drug response.",
        "URL": "https://www.pharmgkb.org/"
    },
    {
        "key": "23",
        "Catagory": "Gene-Drug",
        "Database": "DrugBank",
        "Description": "DrugBank database is a comprehensive, free-to-access, online database containing information on drugs and drug targets. ",
        "URL": "https://go.drugbank.com/"
    },
    {
        "key": "24",
        "Catagory": "Disease mutation",
        "Database": "ClinVar",
        "Description": "ClinVar is a freely accessible, public archive of reports of the relationships among human variations and phenotypes, with supporting evidence. The database includes germline and somatic variants of any size, type or genomic location. Interpretations are submitted by clinical testing laboratories, research laboratories, locus-specific databases, OMIM, GeneReviews, UniProt, expert panels and practice guidelines.",
        "URL": "https://www.ncbi.nlm.nih.gov/clinvar"
    },
    {
        "key": "25",
        "Catagory": "Disease mutation",
        "Database": "DVD",
        "Description": "Deafness Variation Database (DVD) provides a comprehensive guide to genetic variation in genes known to be associated with deafness.",
        "URL": "http://www.deafnessvariationdatabase.org"
    },
    {
        "key": "26",
        "Catagory": "Disease mutation",
        "Database": "HGMD",
        "Description": "HGMD represents an attempt to collate all known (published) gene lesions responsible for human inherited disease",
        "URL": "http://www.hgmd.cf.ac.uk/ac/index.php"
    },
    {
        "key": "27",
        "Catagory": "Disease mutation",
        "Database": "ChinaMAP",
        "Description": "Population-based human genetics in China for precision medicine leading to prevention and treatment of noninfectious chronic diseases",
        "URL": "http://www.mbiobank.com/"
    },
    {
        "key": "28",
        "Catagory": "Disease mutation",
        "Database": "gnomAD",
        "Description": "The Genome Aggregation Database (gnomAD) is a coalition of investigators seeking to aggregate and harmonize exome and genome sequencing data from a variety of large-scale sequencing projects, and to make summary data available for the wider scientific community.The data set provided on this website spans 730,947 exomes and 76,215 genomes from unrelated individuals sequenced as part of various disease-specific and population genetic studies.",
        "URL": "http://gnomad.broadinstitute.org/"
    },
    {
        "key": "29",
        "Catagory": "Disease mutation",
        "Database": "dbSNP",
        "Description": "The Single Nucleotide Polymorphism database (dbSNP) is a public-domain archive for a broad collection of simple genetic polymorphisms.dbSNP contains human single nucleotide variations, microsatellites, and small-scale insertions and deletions along with publication, population frequency, molecular consequence, and genomic and RefSeq mapping information for both common variations and clinical mutations.",
        "URL": "https://www.ncbi.nlm.nih.gov/snp"
    },
    {
        "key": "30",
        "Catagory": "Literature mining",
        "Database": "LitVar",
        "Description": "LitVar allows the search and retrieval of variant specific information from relevant studies in the literature, with related concept (e.g., diseases) annotations.",
        "URL": "https://www.ncbi.nlm.nih.gov/research/litvar2/"
    },
    {
        "key": "31",
        "Catagory": "Literature mining",
        "Database": "Variant2Literature",
        "Description": "variant2literature is available in two ways. First, a web-based interface is provided to search all the literature in PMC Open Access Subset. Second, the command-line executable can be downloaded such that the users are free to search all the files in a specified directory locally.",
        "URL": "http://variant2literature.taigenomics.com/"
    },
    {
        "key": "32",
        "Catagory": "Prediction Software",
        "Database": "DVPred",
        "Description": "The Deafness Variant Function Prediction Tool (DVPred) is an in-silico classifier evaluating deleterious impact of genetic variation in casual genes of hereditary hearing loss.",
        "URL": "http://gdcdb.net/tool"
    },
    {
        "key": "33",
        "Catagory": "Prediction Software",
        "Database": "SIFT",
        "Description": "SIFT is a sequence homology-based tool that sorts intolerant from tolerant amino acid substitutions and predicts whether an amino acid substitution in a protein will have a phenotypic effect. SIFT is based on the premise that protein evolution is correlated with protein function. ",
        "URL": "https://sift.bii.a-star.edu.sg/"
    },
    {
        "key": "34",
        "Catagory": "Prediction Software",
        "Database": "PolyPhen-2",
        "Description": "PolyPhen-2 is a tool which predicts possible impact of an amino acid substitution on the structure and function of a human protein using straightforward physical and comparative considerations.This prediction is based on a number of features comprising the sequence, phylogenetic and structural information characterizing the substitution.",
        "URL": "http://genetics.bwh.harvard.edu/pph2"
    },
    {
        "key": "35",
        "Catagory": "Prediction Software",
        "Database": "LRT",
        "Description": "A likelihood ratio test (LRT) can accurately identify a subset of deleterious mutations that disrupt highly conserved amino acids within protein-coding sequences, which are likely to be unconditionally deleterious.",
        "URL": "http://www.genetics.wustl.edu/jflab/lrt_query.html"
    },
    {
        "key": "36",
        "Catagory": "Prediction Software",
        "Database": "MutationTaster",
        "Description": "MutationTaster employs a Bayes classifier to eventually predict the disease potential of an alteration. The Bayes classifier is fed with the outcome of all tests and the features of the alterations and calculates probabilities for the alteration to be either a disease mutation or a harmless polymorphism.",
        "URL": "http://www.mutationtaster.org"
    },
    {
        "key": "37",
        "Catagory": "Prediction Software",
        "Database": "MutationAssessor",
        "Description": "MutationAssessor predicts the functional impact of amino-acid substitutions in proteins, such as mutations discovered in cancer or missense polymorphisms. The functional impact is assessed based on evolutionary conservation of the affected amino acid in protein homologs.",
        "URL": "http://mutationassessor.org"
    },
    {
        "key": "38",
        "Catagory": "Prediction Software",
        "Database": "FATHMM",
        "Description": "Functional Analysis through Hidden Markov Models(FATHMM) is specifically designed for non-synonymous single nucleotide variants (nsSNVs), and non-coding variants in the human genome.",
        "URL": "http://fathmm.biocompute.org.uk"
    },
    {
        "key": "39",
        "Catagory": "Prediction Software",
        "Database": "PROVEAN",
        "Description": "Protein Variation Effect Analyzer (PROVEAN) is a software tool which predicts whether an amino acid substitution or indel has an impact on the biological function of a protein. It is useful for filtering sequence variants to identify nonsynonymous or indel variants that are predicted to be functionally important.",
        "URL": "http://provean.jcvi.org/"
    },
    {
        "key": "40",
        "Catagory": "Prediction Software",
        "Database": "M-CAP",
        "Description": "M-CAP is the first pathogenicity classifier for rare missense variants in the human genome that is tuned to the high sensitivity required in the clinic (see Table). By combining previous pathogenicity scores (including SIFT, Polyphen-2 and CADD) with novel features and a powerful model, we attain the best classifier at all thresholds, reducing a typical exome/genome rare (<1%) missense variant (VUS) list from 300 to 120, while never mistaking 95% of known pathogenic variants as benign.",
        "URL": "http://bejerano.stanford.edu/MCAP"
    },
    {
        "key": "41",
        "Catagory": "Prediction Software",
        "Database": "CADD",
        "Description": "Combined Annotation Dependent Depletion (CADD) is a tool for scoring the deleteriousness of single nucleotide variants as well as insertion/deletions variants in the human genome. It is a framework that integrates multiple annotations into one metric by contrasting variants that survived natural selection with simulated mutations.",
        "URL": "http://cadd.gs.washington.edu/"
    },
    {
        "key": "42",
        "Catagory": "Prediction Software",
        "Database": "GERP++",
        "Description": "GERP++ is a new tool that uses maximum likelihood evolutionary rate estimation for position-specific scoring and, in contrast to previous bottomup methods, a novel dynamic programming approach to subsequently define constrained elements. GERP++ evaluates a richer set of candidate element breakpoints and ranks them based on statistical significance, eliminating the need for biased heuristic extension techniques.",
        "URL": "http://mendel.stanford.edu/SidowLab/downloads/gerp/index.html"
    },
    {
        "key": "43",
        "Catagory": "Prediction Software",
        "Database": "DANN",
        "Description": "DANN is a deep learning approach for annotating the pathogenicity of whole-genome genetic variants. DANN uses the same feature set and training data as CADD to train a deep neural network (DNN). DNNs can capture non-linear relationships among features and are better suited than SVMs for problems with a large number of samples and features.",
        "URL": "https://cbcl.ics.uci.edu/public_data/DANN/"
    },
    {
        "key": "44",
        "Catagory": "Prediction Software",
        "Database": "fathmm-MKL",
        "Description": "fathmm-MKL is capable of predicting the functional effects of protein missense mutations by combining sequence conservation within hidden Markov models (HMMs).",
        "URL": "http://fathmm.biocompute.org.uk"
    },
    {
        "key": "45",
        "Catagory": "Prediction Software",
        "Database": "Eigen",
        "Description": "Eigen is a spectral approach to the functional annotation of genetic variants in coding and noncoding regions. Eigen makes use of a variety of functional annotations in both coding and noncoding regions (such as made available by the ENCODE and Roadmap Epigenomics projects), and combines them into one single measure of functional importance.",
        "URL": "http://www.columbia.edu/~ii2135/eigen.html"
    },
    {
        "key": "46",
        "Catagory": "Prediction Software",
        "Database": "GenoCanyon",
        "Description": "GenoCanyon is a whole-genome functional annotation approach based on unsupervised statistical learning. It integrates genomic conservation measures and biochemical annotation data to predict the functional potential at each nucleotide.",
        "URL": "https://zhaocenter.org/GenoCanyon_Index.html"
    },
    {
        "key": "47",
        "Catagory": "Prediction Software",
        "Database": "fitCons",
        "Description": "The fitness consequences of functional annotation(fitCons) integrates functional assays (such as ChIP-Seq) with selective pressure inferred using the INSIGHT method. The result is a score \u03c1 in the range [0.0-1.0] that indicates the fraction of genomic positions evincing a particular pattern (or 'fingerprint') of functional assay results, that are under selective pressure.",
        "URL": "http://compgen.cshl.edu/fitCons/"
    },
    {
        "key": "48",
        "Catagory": "Prediction Software",
        "Database": "PhyloP",
        "Description": "PhyloP scores measure evolutionary conservation at individual alignment sites.And the phyloP scores are useful to evaluate signatures of selection at particular nucleotides or classes of nucleotides (e.g., third codon positions, or first positions of miRNA target sites).",
        "URL": "http://compgen.cshl.edu/phast/phyloP-tutorial.php"
    },
    {
        "key": "49",
        "Catagory": "Prediction Software",
        "Database": "PhastCons",
        "Description": "PHAST is a freely available software package for comparative and evolutionary genomics. It consists of about half a dozen major programs, plus more than a dozen utilities for manipulating sequence alignments, phylogenetic trees, and genomic annotations.",
        "URL": "http://compgen.cshl.edu/phast/phastCons-tutorial.php"
    },
    {
        "key": "50",
        "Catagory": "Prediction Software",
        "Database": "SiPhy",
        "Description": "SiPhy is a approach that takes advantage of deeply sequenced clades to identify evolutionary selection by uncovering not only signatures of rate-based conservation but also substitution patterns characteristic of sequence undergoing natural selection.",
        "URL": "http://portals.broadinstitute.org/genome_bio/siphy/"
    },
    {
        "key": "51",
        "Catagory": "Prediction Software",
        "Database": "REVEL",
        "Description": "REVEL is a new ensemble method for predicting the pathogenicity of missense variants based on a combination of scores from 13 individual tools: MutPred, FATHMM v2.3, VEST 3.0, Polyphen-2, SIFT, PROVEAN, MutationAssessor, MutationTaster, LRT, GERP++, SiPhy, phyloP, and phastCons. REVEL was trained using recently discovered pathogenic and rare neutral missense variants, excluding those previously used to train its constituent tools.",
        "URL": "https://sites.google.com/site/revelgenomics/"
    },
    {
        "key": "52",
        "Catagory": "Prediction Software",
        "Database": "ReVe",
        "Description": "ReVe is a method that combinated of REVEL and VEST3, to predict the pathogenicity of missense variants. ReVe showed the best performance for distinguishing pathogenic variants.",
        "URL": "http://varcards.biols.ac.cn"
    }
];

export { database_columns, database_source };