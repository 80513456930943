import React, { Component } from 'react';
import { Row, Col, Input, Select, AutoComplete, message, Typography, Table } from 'antd';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import asyncFetch from '../../utils/asyncFetch';

import { _gene, _variant, _hpo, _disease } from './table_config';

import geneLeftTop from '../../images/geneLeftTop.png';
import door from '../../images/door.png'
import geneRightBottom from '../../images/geneRightBottom.png';
import genes from '../../images/genes.png';
import terms from '../../images/terms.png';
import variants from '../../images/variants.png';

const { Search } = Input;
const { Option } = Select;
const { Paragraph } = Typography;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchType: 'gene',
            placeholderText: 'e.g. GJB2',
            autocompleteValue: '',
            hintList: [],
            searchList: [],
            loading: false,
            visiable: true,
            columns: _gene
        }
    };

    changeHolder = (type, defaultValue) => {
        let tips = "", column = [];
        switch (type) {
            case 'gene':
                tips = 'e.g. GJB2';
                column = _gene;
                break;
            case 'disease':
                tips = 'e.g. Usher syndrome';
                column = _disease;
                break;
            case 'phenotype':
                tips = 'e.g. Abnormality of body height';
                column = _hpo;
                break;
            default:
                tips = 'e.g. 13-20187343-A-T';
                column = _variant;
        }
        this.setState({
            searchType: type,
            placeholderText: tips,
            autocompleteValue: typeof(defaultValue) === "string" ? defaultValue : '',
            hintList: [],
            searchList: [],
            visiable: true,
            columns: column
        });
    };

    loadList = (value, searchType) => {
        asyncFetch('GET', `/hint/${searchType}`, { search_key: value, filter: false },
            (res) => {
                this.setState({ loading: true });
                if (res.code === 200) {
                    this.setState({ visiable: false });
                    setTimeout(() => {
                        const result = res.data.map((val, idx) => ({ ...val, key: idx }));
                        this.setState({
                            searchList: result,
                            loading: false
                        });    
                    }, 1000);
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    };

    onSearch = (value) => {
        this.loadList(value, this.state.searchType);
    };

    selectList = value => {
        this.loadList(value, this.state.searchType);
    };

    searchList = value => {
        const { searchType } = this.state;
        asyncFetch('GET', '/hint/' + searchType, {search_key: value, filter: true},
            (res) => {
                let arr = [];
                if (JSON.stringify(res) !== "null") {
                    res.data.forEach((val) => {
                        if (searchType === "variant37") {
                            arr.push({
                                value: val.CHROM + "-" + val.POS + "-" + val.REF + "-" + val.ALT
                            })
                        } else if (searchType === "variant38") {
                            arr.push({
                                value: val.CHROM_38 + "-" + val.POS_38 + "-" + val.REF + "-" + val.ALT
                            })
                        } else {
                            arr.push({
                                value: val
                            })
                        }
                    })
                }
                this.setState({
                    hintList: arr
                })
            },
        {}, 'cors', {}, false);
    };

    handleLink = (e, type) => {
        this.changeHolder(type, e.target.innerHTML);
        this.loadList(e.target.innerHTML, type);
    };

    render() {
        const { searchType, placeholderText, autocompleteValue, hintList, searchList, visiable, loading, columns } = this.state;
        const t = this.props.t
        return (
            <Row>
                <Col span={24} className="home-top">
                    <img src={geneLeftTop} width="50%" className="home-bg-top-gene" alt="Left top gene" />
                    <img src={geneRightBottom} width="20%" className="home-bg-bottom-gene" alt="Right bottom gene" />
                    <img src={door} width="40%" className="home-bg-centor-door" alt="Center huaxi door" />
                    <Row type="flex" justify="center" align="middle" className='home-top-contain'>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 18 }}
                            md={{ span: 18 }}
                            lg={{ span: 18 }}
                            xl={{ span: 18 }}
                        >
                            <span className="home-welcome-title">
                                Welcome to Genetic Deafness Commons
                            </span>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 18 }}
                            md={{ span: 18 }}
                            lg={{ span: 18 }}
                            xl={{ span: 18 }}
                            className="search"
                        >
                            <Input.Group compact>
                                <Select size="large" value={searchType} style={{width: '150px'}} onChange={this.changeHolder}>
                                    <Option value="gene">Gene</Option>
                                    <Option value="variant37">Variant(G.37)</Option>
                                    <Option value="variant38">Variant(G.38)</Option>
                                    <Option value="phenotype">HPO</Option>
                                    <Option value="disease">Disease</Option>
                                </Select>
                                <AutoComplete
                                    defaultValue={autocompleteValue}
                                    options={hintList}
                                    onSelect={this.selectList}
                                    onChange={this.searchList}
                                    style={{width: '50%'}}
                                >
                                    <Search
                                        placeholder={placeholderText}
                                        enterButton={
                                            <>
                                                <SearchOutlined/>
                                                <span style={{marginLeft: '2px'}}>
                                                    Search
                                                </span>
                                            </>
                                        }
                                        size="large"
                                        onSearch={this.onSearch}
                                    />
                                </AutoComplete>
                            </Input.Group>
                        </Col>
                        <Col span={18} className="example">
                            {t('home_example')}:
                            <span>
                                <Link onClick={(e) => this.handleLink(e, 'gene')}>
                                    <em>GJB2</em>
                                </Link>
                            </span>
                            <span>
                                <Link onClick={(e) => this.handleLink(e, 'variant38')}>
                                    13-20189473-C-T
                                </Link>
                                (GRCh38)
                            </span>
                            <span>
                                <Link onClick={(e) => this.handleLink(e, 'variant37')}>
                                    13-20763612-C-T
                                </Link>
                                (GRCh37)
                            </span>
                            <span>
                                <Link onClick={(e) => this.handleLink(e, 'phenotype')}>
                                    Abnormality of body height
                                </Link>
                            </span>
                            <span>
                                <Link onClick={(e) => this.handleLink(e, 'disease')}>
                                    Usher syndrome
                                </Link>
                            </span>
                        </Col>
                        <Col span={12} className="class-item">
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={8}>
                                    <Col span={24}>
                                        <img src={genes} alt="gene" width={115}/>
                                    </Col>
                                    <span>
                                        <h1>
                                            201
                                        </h1>
                                        <span className='statistics-number'>
                                            Genes
                                        </span>
                                    </span>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <Col span={24}>
                                        <img src={terms} alt="terms" width={115}/>
                                    </Col>
                                    <span>
                                        <h1>
                                            2,510
                                        </h1>
                                        <span className='statistics-number'>
                                            Phenotype terms
                                        </span>
                                    </span>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <Col span={24}>
                                        <img src={variants} alt="variants" width={115}/>
                                    </Col>
                                    <span>
                                        <h1>
                                            6,810,432
                                        </h1>
                                        <span className='statistics-number'>
                                            Variants
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="home-bottom">
                    {
                        visiable ? (
                            <Paragraph className="home-description">
                                <div dangerouslySetInnerHTML={{ __html: t('home_introduce') }} />
                            </Paragraph>
                        ) : (
                            <Row className='home-search-result'>
                                <Col span={24}>
                                    <Table
                                        style={{width: '90%', margin: '20px 5% 0'}}
                                        columns={columns}
                                        loading={loading}
                                        dataSource={searchList}
                                        pagination={{
                                            defaultPageSize: 5
                                        }}
                                        scroll={{ x: 'max-content' }}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>
        )
    }
};

const WithHome = (props) => {
    const { t } = useTranslation();
    return <Home {...props} t={t} />
};

export default WithHome;