import { message } from 'antd';

const defaultFailSolution = (res) => {
    if(typeof res !== 'object') {
        message.warn(res);
    }
};

const asyncFetch = (
    method,
    API,
    otherParams,
    success,
    otherHeader = {},
    corsSetting = 'same-origin',
    fail = defaultFailSolution,
    rawResp = false
) => {
    const url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5000' : 'http://124.223.194.210:8090';
    let api = API;
    let init = {
        method: method.toUpperCase(),
        mode: corsSetting,
        credentials: 'include'
    };

    if (method === 'GET' || method === 'HEAD') {
        let urlSearch = [];
        for (let key in otherParams) {
            if (otherParams.hasOwnProperty(key)) {
                urlSearch.push(key + '=' + otherParams[key])
            }
        }
        api += urlSearch.length === 0 ? '' : ('?' + urlSearch.join('&'))
    } else {
        init['headers'] = {
            'Content-Type': 'application/json;charset=utf-8;'
        }
        init['body'] = JSON.stringify(otherParams)
    }
    fetch(url + api, init).then(response => {
        if (rawResp) {
           success(response);
            return;
        }
        if (response.status !== 200) {
            response.text().then(text => {
                message.error({
                    content: text
                });
            });
            return;
        }
        try {
            response.json().then(res => {
                success(res)
            });
        } catch(err) {
            message.error({
                content: err.message
            })
        }
    }, (error) => {
        message.error({
            content: 'Error getting data, please refresh the page. ' + error
        })
    }).catch(function(err) {
        message.error({
            content: err.message
        });
    })
};

export default asyncFetch;