import React from 'react';
import{ Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import lineBackground from '../../images/lineBackground.png';
const { Paragraph } = Typography;

const Contact = () => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col span={24} className="page-top-seize-seat" />
            <Col span={24}>
                <img src={lineBackground} className="line-background" alt="title background" />
                <Col span={20} offset={2} style={{marginTop: '6vh'}}>
                    <span className="page-title">
                        {t('contact_title_1')}
                    </span>
                </Col>
                <section className='contact-page'>
                    <div className='subsection'>
                        <Paragraph>
                            {t('use_policy_1')}
                        </Paragraph>
                        <Paragraph>
                            {t('use_policy_2')}
                        </Paragraph>
                    </div>
                </section>
                <Col span={20} offset={2} style={{marginTop: '6vh'}}>
                    <span className="page-title">
                        {t('contact_title_3')}
                    </span>    
                </Col>
                <section className='contact-page'>
                    <div className='subsection'>
                        <Paragraph>
                            {t('contact_item_1')}
                        </Paragraph>
                        <Paragraph>
                            <ul>
                                <li>
                                    Prof. Yuan, Huijun (yuanhuijun@tmmu.edu.cn)
                                </li>
                                <li>
                                    Prof. Cheng, Jing (chengjing@wchscu.cn)
                                </li>
                            </ul>
                        </Paragraph>
                    </div>
                    <div className='subsection'>
                        <Paragraph>
                            {t('contact_item_2')}
                        </Paragraph>
                        <Paragraph>
                            <ul>
                                <li>
                                    Dr. Lu, Yu (luyu@wchscu.cn)
                                </li>
                            </ul>
                        </Paragraph>
                    </div>
                    <div className='subsection'>
                        <Paragraph>
                            {t('contact_item_3')}
                        </Paragraph>
                        <Paragraph>
                            <ul>
                                <li>
                                    Dr. Bu, Fengxiao (bufengxiao@wchscu.cn)
                                </li>
                            </ul>
                        </Paragraph>
                    </div>
                </section>
                {/* <Col span={20} offset={2} style={{marginTop: '6vh'}}>
                    <span className="page-title">
                        {t('contact_title_2')}
                    </span>    
                </Col>
                <section className='contact-page'>
                    <div className='subsection'>
                        <Paragraph>
                            {t('acknowledgment_description_1')}
                        </Paragraph>
                        <Paragraph>
                            {t('acknowledgment_description_2')}
                        </Paragraph>
                        <List
                            size='small'
                            bordered={false}
                            dataSource={t('acknowledgment_list').split(',')}
                            renderItem={item => <List.Item style={{width: '50%', float: 'left'}}>{item}</List.Item>}
                        />
                    </div>
                </section> */}
            </Col>
        </Row>
    )
}

export default Contact