import React, { Component } from 'react';
import { color_scale } from '../../configs/color';

function _createSvg(tag, obj) {
    const oTag = document.createElementNS('http://www.w3.org/2000/svg', tag);
    for (const attr in obj) {
        oTag.setAttribute(attr, obj[attr])
    }
    return oTag
}

function _createText(tag, obj, content) {
    const tTag = document.createElementNS('http://www.w3.org/2000/svg', tag);
    tTag.innerHTML = content
    for (const attr in obj) {
        tTag.setAttribute(attr, obj[attr])
    }
    tTag.setAttribute('style', 'line-height: 20px')
    return tTag
}

class PlotDomain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: this.props.record
        }
    }

    componentDidMount() {
        let _d = this.handleDataStructure(this.state.record)
        let figureElement = document.getElementById('domainFigure')
        let _wholeEnd = _d['end']
        const svgMain = _createSvg('svg', {
            xlmns: 'http://www.w3.org/2000/svg',
            'xlmns:xlink': 'http://www.w3.org/1999/xlink',
            version: '1.1',
            width: '100%',
            height: 30 * this.state.record.length,
            id: 'domainSVG'
        })
        
        let boxWidth = figureElement.clientWidth - 165
        const scale = parseFloat((boxWidth / _wholeEnd).toFixed(3))

        let i = 1
        _d['children'].forEach((val, idx) => {
            val['children'].forEach((v) => {
                let _n = {
                    x: 0,
                    y: 20 * (i + 2) + idx * 10,
                    name: val.name
                }

                let _m = {
                    x: v.start * scale + 150,
                    y: 20 * (i + 1) + idx * 10,
                    width: (v.end - v.start) * scale,
                    height: 20,
                    fill: color_scale[i % color_scale.length],
                    name: val.name
                }

                let _r = _createSvg('rect', _m)
                let overEvent = this.moveEnter.bind(this, v)
                _r.addEventListener('mouseover', overEvent)
                _r.addEventListener('mouseout', this.moveOut)
                let _t = _createText('text', _n, val.name)
                _t.setAttribute('style', 'cursor: default; font-size: 16px, line-height: 20px;')

                svgMain.appendChild(_r)
                svgMain.appendChild(_t)
                i++
            })
        })

        let _l =  {
            x1: 150,
            y1: 18,
            x2: boxWidth + 150,
            y2: 18,
        }

        for (let tick = 0; tick <= _wholeEnd; tick++) {
            let _tick = {
                x1: 150 + tick * scale,
                y1: tick % 5 === 0 ? 13 : 15,
                x2: 150 + tick * scale,
                y2: 18
            }
            let _tick_text = {
                x: 150 + tick * scale,
                y: 12
            }
            if (tick % 50 === 0) {
                let _ttt = _createText('text', _tick_text, tick)
                _ttt.setAttribute("style", "text-anchor: middle")
                svgMain.appendChild(_ttt)
            }

            let _tt = _createSvg('line', _tick)
            _tt.setAttribute("style", `stroke: black`)
            svgMain.appendChild(_tt)
        }

        let _lx = _createText('line', _l)
        _lx.setAttribute("style", `stroke: black`)
        svgMain.appendChild(_lx)

        figureElement.appendChild(svgMain)
    }

    componentWillUnmount() {
        let figureElement = document.getElementById('domainFigure')
        let svgElement = document.getElementById('domainSVG')
        figureElement.removeChild(svgElement)
    }

    moveEnter = (val, e) => {
        let tip = document.createElement('div')
        let figureElement = document.getElementById('domainFigure')
        tip.setAttribute("id", "domainItem")
        tip.setAttribute("style", `width: 200px; heigth: 300px; position: fixed; top: ${e.clientY}px; left: ${e.clientX}px; z-index: 999; background: #000; color: #fff; border-radius: 3px; padding: 8px`)
        tip.innerHTML = `name: ${val.name} <br /> start: ${val.start} <br /> end: ${val.end}`
        figureElement.appendChild(tip)
    }

    moveOut = () => {
        let domainItem = document.getElementById('domainItem')
        let figureElement = document.getElementById('domainFigure')
        figureElement.removeChild(domainItem)
    }

    handleSmart = (key, array) => {
        let smartArray = []
        array.forEach((val) => {
            if (key === val['SMART_ID'])
            smartArray.push({
                name: val['Short_name'],
                start: val['Domain_start'],
                end: val['Domain_end']
            })
        })
        return {
            name: key,
            children: smartArray
        }
    }

    handleDataStructure = (data) => {
        let _end = [], _smart = [], _children = []
        data.forEach((val) => {
            _end.push(val['Domain_end'])
            _smart.push(val['SMART_ID'])
        })
        _smart = [...new Set(_smart)]
        _smart.forEach((val) => {
            let tmp = this.handleSmart(val, data)
            _children.push(tmp)
        })
        return {
            name: data[0]['SYMBOL'],
            start: 0,
            end: Math.max(..._end),
            children: _children
        }
    }

    render() {
        return (
            <div id="domainFigure" />
        )
    }
}

export default PlotDomain