import React, { Component } from 'react';
import { Row, Col, message, Segmented, Spin, List, Card, Table  } from 'antd';
import { useTranslation } from 'react-i18next';
import { mouseTableColumns } from './table_coulmns.js';

import lineBackground from '../../images/lineBackground.png';
import asyncFetch from '../../utils/asyncFetch.js';

import ListItem from '../../components/ListItem';

const WithBrowse = (props) => {
    const {t} = useTranslation();
    return <Browse {...props} t={t} />
}

class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            geneList: [],
            mouseGeneList: [],
            currentPage: 1,
            pageSize: 3,
            loading: true
        }
    };

    componentDidMount() {
        this.handleValue('A');
        this.loadMouseData();
    };

    loadData = (value) => {
        asyncFetch('GET', '/browse', {type: 'human', current_page: 1, page_size: 201},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx
                        result.push(val)
                    })
                    this.setState({
                        geneList: result.filter(val => val.SYMBOL.startsWith(value)),
                        loading: false
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    };

    loadMouseData = () => {
        asyncFetch('GET', '/browse', {type: 'mouse', current_page: 1, page_size: 2000},
            (res) => {
                if (res.code === 200) {
                    let result = [];
                    res.data.forEach((val, idx) => {
                        val.key = idx;
                        result.push(val)
                    })
                    this.setState({
                        mouseGeneList: result
                    })
                } else {
                    message.error(res.msg)
                }
            },
        {}, 'cors', {}, false);
    };

    handleValue = (value) => {
        this.loadData(value);
    }

    pageChange = (current, pageSize) => {
        this.setState({
            pageSize,
        })
    }

    render() {
        const { geneList, mouseGeneList, loading, pageSize } = this.state;
        const t = this.props.t;

        return (
            <div>
                <Row>
                    <Col span={24} className="page-top-seize-seat" />
                    <Col span={24} className="page-title-box">
                        <img src={lineBackground} className="line-background" alt="about" />
                        <Col span={20} offset={2} style={{marginTop: '6vh'}}>
                            <span className="page-title">
                                {t('browse_title')}
                            </span>
                        </Col>
                    </Col>
                    <Col span={24} className="browse-contain">
                        <Row>
                            <Col span={24}>
                                <p>
                                    {t('browse_description_1')}
                                </p>
                                <p>
                                    {t('browse_description_2')}
                                </p>
                                <div className='grey_description'>
                                    {t('browse_description_3')}
                                </div>
                            </Col>
                            <Col span={24} style={{marginTop: '20px'}}>
                                <Segmented block='true' size='large' options={[
                                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                                ]} onChange={value => this.handleValue(value)} />
                            </Col>
                            <Col span={24} style={{marginTop: '5vh'}}>
                                <Spin spinning={loading}>
                                    <List
                                        grid={{
                                            gutter: 24,
                                            xs: 1,
                                            sm: 2,
                                            md: 2,
                                            lg: 2,
                                            xl: 3,
                                            xxl: 3
                                        }}
                                        dataSource={geneList}
                                        renderItem={item => (
                                            <Col span={24}>
                                                <List.Item>
                                                    <Card
                                                        className="browse-card"
                                                        title={`Gene: ` + item.SYMBOL}
                                                        actions={[
                                                            <a
                                                                href={"/gene/" + item.SYMBOL}
                                                                key={item.key}
                                                                target="_black"
                                                            >
                                                                - Visit Detail-
                                                            </a>
                                                        ]}
                                                    >
                                                        <em><ListItem label="Alias" content={item.Also_known_as} separate={true} color="#096dd9" background="#e6f7ff" /></em>
                                                        <ListItem label="Coordinates(GRCh37)" content={item["Coordinates(GRCh37)"]} background="transparent" />
                                                        <ListItem label="Location" content={item.Cyto_Location} background="transparent" />
                                                        <ListItem label="Ensembl" content={item.Ensembl} background="transparent" />
                                                        <ListItem label="MIM" content={item.MIM} background="transparent" />
                                                        <ListItem label="Inheritance" content={item.Inheritance_Pattern} background="transparent" />
                                                        <ListItem label="Onset" content={item.Onset} background="transparent" />
                                                        <ListItem label="Hearing loss feature" content={item.Audioprofile} background="transparent" />
                                                        <ListItem label="ClinGen curation" content={item.ClinGen_annotation} separate={true} color="#CF1322" background="#FFF1F0" delimiter=';' />
                                                        <ListItem label="Nonsyndromic hearing loss" content={item["Non-syndromic_HL"]} color="#f27522" background="#FFF1DD"  />
                                                        <ListItem label="Syndromic hearing loss" content={item.Syndromic_HL} separate={true} color="#e64545" background="#FFDDDD" delimiter=';' />
                                                    </Card>
                                                </List.Item>
                                            </Col>
                                        )}
                                        pagination={{
                                            showSizeChanger: true,
                                            pageSizeOptions: [3, 6, 12, 24],
                                            onShowSizeChange: this.pageChange,
                                            pageSize: pageSize,
                                            total: geneList.length,
                                        }}
                                    />
                                </Spin>
                            </Col>
                            <Col span={24} style={{ marginTop: '20px' }}>
                                <div className='grey_description'>
                                    {t('browse_description_4')}
                                </div>
                            </Col>
                            <Col span={24} style={{ marginTop: '20px' }}>
                                <Table
                                    columns={mouseTableColumns}
                                    dataSource={mouseGeneList}
                                    loading={loading}
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default WithBrowse